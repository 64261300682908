
import React from 'react'
import { AiOutlineYoutube } from 'react-icons/ai'
import { FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa'
import { RiTwitterXLine } from 'react-icons/ri'
import { SlSocialFacebook, SlSocialLinkedin } from 'react-icons/sl'

const HeaderSocialMedia = () => {
  return (
    <div className='flex justify-between w-fit gap-x-2 md:gap-x-4'>
      {/* Whatsapp */}
      <a
        href='https://wa.me/message/26AJURO347ESI1'
        target='_blank'
        rel='noopener noreferrer'
        className='w-6 h-6 md:w-10 md:h-10 group flex items-center justify-center bg-green-400 rounded-full transition-all duration-500'
      >
        <FaWhatsapp className='transition-all duration-500 group-hover:text-white text-black text-md md:text-xl' />
      </a>
    

    </div>
  )
}

export default HeaderSocialMedia
