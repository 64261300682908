export const Values = {
    valuesEn: [
        'Integrity & Commitment',
        'Client-Centric Approach',
        'Expertise',
        'Accountability',
        'Innovation',
        'Trustworthy',
        'Professionalism',
        'Accessibility',
        'Education',
        'Long-Term Relationships',
        'Community Engagement',
        'Responsibility'
    ],
    valuesAr: [
       'النزاهة والالتزام',
       'نهج يركز على العميل',
       'خبرة',
       'المساءلة',
       'ابتكار',
       'جدير بالثقة',
       'الاحترافية',
       'إمكانية الوصول',
       'تعليم',
       'العلاقات طويلة الأمد',
       'المشاركة المجتمعية',
       'مسؤولية',
        
    ]
}