import React from 'react'
import MainLayout from '../../components/MainLayout'
import Video from './containers/Video'
import Discover from './containers/Discover'
import Awards from './containers/Awards'
import AboutUs from './containers/AboutUs'
import ContactUs from './containers/ContactUs'
import Blogs from './containers/Blogs'
import MissionNVision from './containers/MissionNVision'
import Partners from './containers/Partners'
import TopDevelopers from './containers/TopDevelopers'
import SEO from '../../components/SEO'


const HomePage = () => {
  return (
    <div id='homepage'>
      <SEO 
        title={'Home | Nubaq'}
        description={'Find your dream home with Nubaq comprehensive real estate services. Explore apartments, villas, and more tailored to your needs.'}
        keywords={'real estate, buy, house, home, apartments, villas, property, properties, sell, finish, rent, duplex, twin house, chalet, north coast, sahel, gouna, sokhna, cairo, new capital, zayed'}
        url={'https://www.nubaq.com'}
        image={'https://www.nubaq.com/assets/Icon.png'}
      />
      <MainLayout>
        <Video />
        <AboutUs />
        <MissionNVision/>
        <Partners />
        <TopDevelopers />
        <Discover />
        <Awards />
        <Blogs />
        <ContactUs />
      </MainLayout>
    </div>
  )
}

export default HomePage
