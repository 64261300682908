import React, { useContext } from 'react'
import UnitComponent from '../../../components/UnitComponent'
import { AppContext } from '../../../AppContext'

const AllUnitsSection = ({ units }) => {

    const { isSelectedLanguageEnglish } = useContext(AppContext);

    return (
        <div className='flex flex-col p-4 m-4 border shadow-md rounded-lg'>
            <div className='flex justify-between items-center p-2'>
                <span className={`text-2xl w-full mx-8 font-semibold underline underline-offset-8 ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'All Units' : 'كل الوحدات'}</span>
            </div>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 m-4'>
                {
                    units.map((unit) => {
                        return (
                            <UnitComponent unit={unit} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AllUnitsSection
