import React, { useState, useEffect } from 'react';

const ImageOutlet = ({ images, interval = 3000 }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => setFade(false), interval - 500); // Start fading out before the interval
    return () => clearTimeout(timeoutId);
  }, [currentImageIndex, interval]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(true); // Start fading in the new image
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    return () => clearInterval(intervalId);
  }, [images.length, interval]);

  return (
    <div className="relative w-full h-full overflow-hidden">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Image ${index + 1}`}
          className={`absolute top-0 left-0 w-full h-[350px] md:h-[550px] lg:h-full transition-opacity duration-500 ${
            index === currentImageIndex && fade ? 'opacity-100' : 'opacity-0'
          }`}
          style={{
            transitionDuration: '500ms', transition: 'opacity 500ms ease-in-out'
          }}
        />
      ))}
    </div>
  );
};

export default ImageOutlet;
