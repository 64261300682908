import React, { useState, useEffect, useContext } from 'react';
import MainLayout from '../../components/MainLayout';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import UnitComponent from '../../components/UnitComponent';
import SEO from '../../components/SEO';

const ProjectPage = () => {
  const { getProjectById, getUnitsFromProject, loading: contextLoading, isSelectedLanguageEnglish } = useContext(AppContext);
  const { id } = useParams();

  const [project, setProject] = useState(null);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDeveloper = async () => {
      if (contextLoading) return; // Wait for context to finish loading
      try {
        const projectsData = await getProjectById(id);
        if (!projectsData) {
          throw new Error("Error fetching project data")
        }
        const unitsData = await getUnitsFromProject(projectsData.name);
        if (!unitsData) {
          throw new Error("Error fetching units data")
        }
        setProject(projectsData);
        setUnits(unitsData);
      } catch (err) {
        setError(err.message || 'Failed to fetch project data.');
      } finally {
        setLoading(false);
      }
    };

    fetchDeveloper();
  }, [getProjectById, getUnitsFromProject, id, contextLoading]);

  if (contextLoading || loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <SEO
        title={`${project.name} | Nubaq`}
        description={'Find your dream home with Nubaq comprehensive real estate services. Explore apartments, villas, and more tailored to your needs.'}
        keywords={'real estate, buy, house, home, apartments, villas, property, properties, sell, finish, rent, duplex, twin house, chalet, north coast, sahel, gouna, sokhna, cairo, new capital, zayed'}
        url={'https://www.nubaq.com/projects/:id'}
        image={'https://www.nubaq.com/assets/Icon.png'}
      />
      <MainLayout>
        <div className='pt-40 w-full flex flex-col gap-4'>
          <div className='w-full flex flex-col lg:flex-row items-center justify-center'>
            <div className='flex flex-col gap-4 w-full justify-center items-center px-4'>
              <h1 className='font-semibold text-center text-[20px] md:text-[26px] lg:text-[32px]'>{isSelectedLanguageEnglish ? project.name : project.arabicName}</h1>
              <img className='w-2/3 lg:w-full' src={project.logoLink} alt='project Logo' />
            </div>
            <h1 className='p-4 text-center lg:text-left sm:text-[10px] md:text-[20px] lg:text-[24px]'>{isSelectedLanguageEnglish ? project.description : project.arabicDescription}</h1>
          </div>
          <div className='m-2 border-2 border-nubaqgreen rounded-xl p-4 flex flex-col'>
            <div className={`text-[24px] font-semibold text-nubaqgreen ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'Units' : 'الوحدات'}</div>
            <div className='p-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
              {units.map((unit) => (
                <UnitComponent unit={unit} />
              ))}
            </div>
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default ProjectPage;
