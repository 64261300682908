import React, { useContext, useState } from 'react'
import { MdCancel } from 'react-icons/md';
import { BiError } from 'react-icons/bi';
import { TiTick } from 'react-icons/ti';
import axios from 'axios';
import { Select } from '@headlessui/react';
import { AppContext } from '../../../AppContext';

const Form = () => {

  const { isSelectedLanguageEnglish, locationsOptions, PropertyTypeOptions, getZone } = useContext(AppContext)

  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientNumber: '',
    title: '',
    description: '',
    location: '',
    zone: '',
    address: '',
    images: [],
    developer: '',
    project: '',
    bedrooms: '',
    bathroom: '',
    area: '',
    propertyType: '',
    landArea: '',
    floor: '',
    garden: '',
    roof: '',
    message: '',
    status: "Open",
    requestType: 'Finish'
  });

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [addedSuccessfully, setAddedSuccessfully] = useState(false);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const handleFileChange = async (e) => {
    setFiles(e.target.files);
  };

  const handleImageUpload = async () => {
    const form = new FormData();

    // Append all files to the FormData
    Array.from(files).forEach((file) => form.append('images', file));
    form.append('name', formData.title);
    form.append('description', formData.description);

    try {
      const response = await axios.post(
        'https://legendary-cucurucho-9bea38.netlify.app/.netlify/functions/api/api/images',
        form,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      const links = response.data.urls;

      const updatedLinks = links.map((link) =>
        "https://pub-a5d818f1935543108e4e891f9bc54d8c.r2.dev/" + link.substring(link.lastIndexOf('/') + 1)
      );
      return updatedLinks;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  if (loading) {
    let circleCommonClasses = 'h-2.5 w-2.5 bg-green-400   rounded-full';

    return (
      <div className='flex items-center justify-center w-full h-screen-minus-245'>
        <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
        <div
          className={`${circleCommonClasses} mr-1 animate-bounce200`}
        ></div>
        <div className={`${circleCommonClasses} animate-bounce400`}></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='flex flex-col w-full h-screen-minus-245 pt-24'>
        <div className='flex items-end justify-end w-full px-24 py-2'>
          <button onClick={() => setError(null)}> <MdCancel className='text-black text-[24px]' /></button>
        </div>
        <div className='w-full h-screen-minus-245 flex flex-col gap-8 items-center justify-center text-center'>
          <BiError className='text-[48px] text-red-600' />
          <span className='text-md text-gray-800'>Error: {error}</span>
        </div>
      </div>
    );
  }

  if (addedSuccessfully) {
    return (
      <div className='flex flex-col w-full h-screen-minus-245'>
        <div className='flex items-end justify-end w-full px-24 py-2'>
          <button onClick={() => setAddedSuccessfully(false)}> <MdCancel className='text-black text-[24px]' /></button>
        </div>
        <div className='w-full h-screen-minus-245 flex flex-col gap-8 items-center justify-center text-center'>
          <TiTick className='text-[48px] text-green-500' />
          <span className='text-md text-gray-800'>Request submitted successfully</span>
        </div>
      </div>
    );
  }

  const clearForm = () => {
    setFormData({
      clientName: '',
      clientEmail: '',
      clientNumber: '',
      title: '',
      description: '',
      location: '',
      zone: '',
      address: '',
      images: [],
      developer: '',
      project: '',
      bedrooms: '',
      bathroom: '',
      area: '',
      propertyType: '',
      landArea: '',
      floor: '',
      garden: '',
      roof: '',
    });
    setFiles([]);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      formData.address === '' ||
      formData.area === '' ||
      formData.bedrooms === '' ||
      formData.bathroom === '' ||
      formData.description === '' ||
      formData.propertyType === '' ||
      formData.title === '' ||
      files.length === 0
    ) {
      setError('Check that you entered all data correctly');
      setLoading(false);
      return;
    }

    try {
      let links = await handleImageUpload();

      setFormData((prevData) => ({
        ...prevData,
        images: links,
      }));
      if (formData.images.length === 0) {
        throw new Error("Error uploading images")
      }
      await axios.post(
        'https://legendary-cucurucho-9bea38.netlify.app/.netlify/functions/api/api/property-requests',
        { params: { formData: { ...formData, images: links } } }
      ).then((response) => {
        console.log('Request Submitted:', response);
        setAddedSuccessfully(true);
        clearForm();
      }).catch((err) => {
        setError(err.response.data.msg);
      });
    } catch (err) {
      setError(err.message);
      console.log(err.message);
    }
    setLoading(false);
  };


  return (
    <div className='flex flex-col gap-4 p-4 pt-36'>

      <div className='w-full p-4 text-center text-nubaqgreen text-[38px] font-semibold'>{isSelectedLanguageEnglish ?
        'Tailor your space with perfection'
        :
        'قم بتصميم المساحة الخاصة بك لتكن نموذاجاً للكمال'
      }</div>

      {/* Personal Information */}
      <div className='w-full p-4 rounded-3xl shadow-lg flex flex-col items-center justify-center gap-2 border-2 border-nubaqgreen'>
        <span className={`w-full flex items-center justify-start text-2xl font-semibold m-4 ${!isSelectedLanguageEnglish && 'justify-end'}`}>{isSelectedLanguageEnglish ? 'Personal Information' : 'بيانات شخصية'}</span>
        <div className='w-full border border-gray-300'></div>
        <div className={`flex flex-col md:flex-row gap-4 w-full justify-between text-lg p-4 ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
          <div className='flex flex-col w-full'>
            <span className={`text-[19px] font-semibold px-2 ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'Name' : 'الأسم'}</span>
            <input
              required
              type='text'
              name='clientName'
              value={formData.clientName}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className={`text-[19px] font-semibold px-2 ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'Email' : 'البريد الإليكترونى'}</span>
            <input
              required
              type='text'
              name='clientEmail'
              value={formData.clientEmail}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className={`text-[19px] font-semibold px-2 ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'Number' : 'رقم الهاتف'}</span>
            <input
              required
              type='text'
              name='clientNumber'
              value={formData.clientNumber}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
        </div>
        <div className='flex flex-col w-full px-4 pb-4'>
          <span className={`text-[19px] font-semibold px-2 ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'Message' : 'الرسالة'}</span>
          <textarea
            required
            type='text'
            name='message'
            value={formData.message}
            onChange={handleInputChange}
            className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
          />
        </div>
      </div>
      <div className='w-full p-4 rounded-3xl shadow-lg flex flex-col items-center justify-center gap-2 border-2 border-nubaqgreen'>
        <span className={`w-full flex items-center justify-start text-2xl font-semibold m-4 ${!isSelectedLanguageEnglish && 'justify-end'}`}>{isSelectedLanguageEnglish ? 'Property Information' : 'بيانات الوحدة'}</span>
        <div className='w-full border border-gray-300'></div>
        <div className='flex flex-col md:grid md:grid-cols-2 gap-4 w-full text-lg p-4'>

          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Title</span>
            <input
              required
              type='text'
              name='title'
              value={formData.title}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Description</span>
            <input
              required
              type='text'
              name='description'
              value={formData.description}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Images</span>
            <input
              required
              type="file"
              name="images"
              multiple
              onChange={handleFileChange}
              className="border-2 bg-white p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Location</span>
            <Select required className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500" value={formData.location} onChange={(e) => setFormData({ ...formData, location: e.target.value })}>
              {locationsOptions.map((option, index) => (
                <option value={option.value} key={index}>{option.label}</option>
              ))}
            </Select>
          </div>

          {
            (formData.location && formData.location !== 'UAE_Dubai' && formData.location !== 'الأمارات دبى')
            && (
              <div className='flex flex-col w-full'>
                <span className='text-[19px] font-semibold pl-2'>Zone</span>
                <Select required className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500" value={formData.zone} onChange={(e) => setFormData({ ...formData, zone: e.target.value })}>
                  {getZone(formData.location).map((option, index) => (
                    <option value={option} key={index}>{option}</option>
                  ))}
                </Select>
              </div>
            )
          }

          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Address</span>
            <input
              required
              type='text'
              name='address'
              value={formData.address}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>

          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Developer</span>
            <input
              required
              type='text'
              name='developer'
              value={formData.developer}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Project</span>
            <input
              required
              type='text'
              name='project'
              value={formData.project}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Bedrooms</span>
            <input
              required
              type='text'
              name='bedrooms'
              value={formData.bedrooms}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Bathrooms</span>
            <input
              required
              type='text'
              name='bathroom'
              value={formData.bathroom}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Area (m²)</span>
            <input
              required
              type='text'
              name='area'
              value={formData.area}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Property Type</span>
            <Select required className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500" value={formData.propertyType} onChange={(e) => setFormData({ ...formData, propertyType: e.target.value })}>
              {PropertyTypeOptions.map((option, index) => (
                <option value={option.value} key={index}>{option.value}</option>
              ))}
            </Select>
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Land Area</span>
            <input
              required
              type='text'
              name='landArea'
              value={formData.landArea}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Floor</span>
            <input
              required
              type='text'
              name='floor'
              value={formData.floor}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Garden</span>
            <input
              required
              type='text'
              name='garden'
              value={formData.garden}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-[19px] font-semibold pl-2'>Roof</span>
            <input
              required
              type='text'
              name='roof'
              value={formData.roof}
              onChange={handleInputChange}
              className="border-2 p-2 w-full focus:outline-none rounded-xl border-green-300 focus:border-green-500"
            />
          </div>
          <button onClick={handleSubmit} className={`col-span-2 w-full transition-all duration-500 rounded-3xl p-4 border-2 border-nubaqgreen bg-nubaqgreen font-semibold text-white text-center hover:bg-green-500 hover:text-black`}>
            Submit
          </button>
        </div>

      </div>
    </div>
  )
}

export default Form
