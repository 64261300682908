export const BlogsContent = {
    blogsEn: [
        {
            title: 'Which one is better for ROI (Return on Investment) in Egypt in 2025 and why ?',
            refrence: '/Which one is better for ROI.pdf',
        },
        {
            title: 'Real Estate Agent or Thief?',
            refrence: '/Real Estate Agent or Thief.pdf',
        },
        {
            title: 'Before buying a property in Egypt in 2025, it’s crucial to take several important steps to ensure a smooth transaction and protect your investment.',
            refrence: '/Before buying a property in Egypt in 2025.pdf',
        }
    ],
    blogsAr: [
        {
            title: 'أيهما أفضل من حيث العائد على الاستثمار في مصر في عام 2025 ولماذا؟',
            refrence: '/أيهما أفضل من حيث العائد على الاستثمار في مصر في عام 2025 ولماذا.pdf',
        },
        {
            title: 'وكيل عقارات أم لص؟',
            refrence: '/وكيل عقارات أم لص.pdf',
        },
        {
            title: 'قبل شراء عقار في مصر في عام 2025، من الضروري اتخاذ عدة خطوات مهمة لضمان إتمام المعاملة بسلاسة وحماية استثمارك.',
            refrence: '/أيهما أفضل من حيث العائد على الاستثمار في مصر في عام 2025 ولماذا.pdf',
        }
    ]
}