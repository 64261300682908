import React, { useRef, useState, useEffect, useContext } from 'react'
import NumbersCounter from '../../../components/NumbersCounter';
import { IoConstruct } from 'react-icons/io5';
import { AiFillFund } from 'react-icons/ai';
import { FaHome } from 'react-icons/fa';
import { AboutUsContent } from '../../../data/AboutUs';
import { AppContext } from '../../../AppContext';
import images from '../../../constants/Images';

const AboutUs = () => {

  const { isSelectedLanguageEnglish } = useContext(AppContext);
  const aboutUs = isSelectedLanguageEnglish ? AboutUsContent.aboutUsEn : AboutUsContent.aboutUsAr;
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState({
    aboutUs2: false,
    aboutUs3: false,
  });

  const toggleExpand = (key) => {
    setIsExpanded((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  useEffect(() => {
    const currentSection = sectionRef.current; // Store the current ref value in a variable

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(currentSection); // Stop observing once it's visible
        }
      },
      { threshold: 0.2 } // Trigger when 20% of the section is visible
    );

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) observer.unobserve(currentSection); // Use the stored variable in cleanup
    };
  }, []);


  return (
    <section
      id="aboutus"
      ref={sectionRef}
      className={`flex flex-col items-center justify-center w-full p-8 sm:p-16 transition-opacity duration-1000 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      <div className="text-2xl sm:text-4xl font-bold text-nubaqgreen text-center">
        {isSelectedLanguageEnglish ? 'Our Story' : 'قصتنا'}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 items-center w-full gap-4">
        {/* Text Content */}
        <div className="flex flex-col mt-4">
          <h1 className="text-lg sm:text-xl font-bold">{aboutUs[1]}</h1>

          {/* For aboutUs[2] */}
          <div className="text-sm sm:text-base">
            <p
              className={`${!isExpanded.aboutUs2 ? "line-clamp-3 sm:line-clamp-none" : ""
                }`}
            >
              {aboutUs[2]}
            </p>
            <button
              onClick={() => toggleExpand("aboutUs2")}
              className="text-blue-500 hover:underline mt-2 sm:hidden"
            >
              {isExpanded.aboutUs2 ? "See Less" : "See More"}
            </button>
          </div>

          {/* For aboutUs[3] */}
          <div className="text-sm sm:text-base mt-2">
            <p
              className={`${!isExpanded.aboutUs3 ? "line-clamp-3 sm:line-clamp-none" : ""
                }`}
            >
              {aboutUs[3]}
            </p>
            <button
              onClick={() => toggleExpand("aboutUs3")}
              className="text-blue-500 hover:underline mt-2 sm:hidden"
            >
              {isExpanded.aboutUs3 ? "See Less" : "See More"}
            </button>
          </div>
        </div>

        {/* Image and Stats */}
        <div className="flex flex-col gap-2 md:gap-10 h-full justify-center">
          <div className="w-full flex items-center justify-center mb-4">
            <img src={images.Opia} className="w-2/3 md:w-1/3 h-auto" alt="About Us" />
          </div>
          {/* Cards Container */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            {/* Card 1 */}
            <div className="flex flex-col items-center justify-center border-2 rounded-xl bg-gray-100 shadow-lg p-4">
              <IoConstruct className="text-[24px] sm:text-[32px] text-green-500 mb-2 sm:mb-4" />
              <div className="flex text-lg sm:text-2xl items-center justify-center font-semibold text-black">
                {isVisible ? <><NumbersCounter n={40} /><span className="text-green-500">+</span></> : null}
              </div>
              <div className="text-sm sm:text-lg font-semibold text-center">
                {isSelectedLanguageEnglish ? 'Developers' : 'مطور'}
              </div>
            </div>
            {/* Card 2 */}
            <div className="flex flex-col items-center justify-center border-2 rounded-xl bg-gray-100 shadow-lg p-4">
              <AiFillFund className="text-[24px] sm:text-[32px] text-green-500 mb-2 sm:mb-4" />
              <div className="flex text-lg sm:text-2xl items-center justify-center font-semibold text-black">
                {isVisible ? <><NumbersCounter n={200} /><span className="text-green-500">+</span></> : null}
              </div>
              <div className="text-sm sm:text-lg font-semibold text-center">
                {isSelectedLanguageEnglish ? 'Projects' : 'مشروع'}
              </div>
            </div>
            {/* Card 3 */}
            <div className="flex flex-col items-center justify-center border-2 rounded-xl bg-gray-100 shadow-lg p-4">
              <FaHome className="text-[24px] sm:text-[32px] text-green-500 mb-2 sm:mb-4" />
              <div className="flex text-lg sm:text-2xl items-center justify-center font-semibold text-black">
                {isVisible ? <><NumbersCounter n={700} /><span className="text-green-500">K+</span></> : null}
              </div>
              <div className="text-sm sm:text-lg font-semibold text-center">
                {isSelectedLanguageEnglish ? 'Units' : 'وحده'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  )
}

export default AboutUs
