import React, { useContext } from 'react';
import { FaLightbulb, FaRocket } from 'react-icons/fa';
import { Mission } from '../../../data/MissionNVision';
import { Vision } from '../../../data/MissionNVision';
import { AppContext } from '../../../AppContext';
import { HiCurrencyDollar } from 'react-icons/hi2';
import { Values } from '../../../data/Values';
import { GoDotFill } from 'react-icons/go';
const MissionNVision = () => {

  const { isSelectedLanguageEnglish } = useContext(AppContext);

  const mission = isSelectedLanguageEnglish ? Mission.missionEn : Mission.missionAr;
  const vision = isSelectedLanguageEnglish ? Vision.visionEn : Vision.visionAr;
  const values = isSelectedLanguageEnglish ? Values.valuesEn : Values.valuesAr

  return (
    <section
      id="mission-vision"
      className="w-full bg-gray-200 py-8 px-4 sm:py-12 sm:px-8 lg:px-16"
    >
      <div className="text-center mb-8 sm:mb-10">
        <h2 className="text-2xl sm:text-4xl font-bold text-nubaqgreen">
          {isSelectedLanguageEnglish ? 'Our Mission & Vision' : 'مهمتنا و رؤيتنا'}
        </h2>
        <p className="text-sm sm:text-md text-gray-600 mt-2">
          {isSelectedLanguageEnglish
            ? 'Nubaq is a concept system of operating estates market, offering a wide array of benefits to clients & developers.'
            : 'نوباك هو نظام مفاهيمي لتشغيل سوق العقارات، يقدم مجموعة واسعة من الفوائد للعملاء والمطورين.'}
        </p>
      </div>

      <div
        className={`grid grid-cols-1 md:grid-cols-2 gap-6 ${!isSelectedLanguageEnglish && 'text-right'
          }`}
      >
        {/* Mission Card */}
        <div className="col-span-1 lg:col-span-2 bg-white p-4 sm:p-6 rounded-lg shadow-lg transition-all duration-500 hover:scale-105 flex flex-col gap-2">
          <div
            className={`flex items-center ${!isSelectedLanguageEnglish && 'flex-row-reverse gap-2'
              }`}
          >
            <FaLightbulb className="text-3xl sm:text-4xl text-nubaqgreen mr-2" />
            <h3 className="text-xl sm:text-2xl font-semibold text-gray-800">
              {isSelectedLanguageEnglish ? 'Our Mission' : 'مهمتنا'}
            </h3>
          </div>
          <div className="font-semibold text-md sm:text-lg">{mission[1]}</div>
          <div className="text-gray-700 text-sm sm:text-md">{mission[2]}</div>
        </div>

        {/* Values Card */}
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg transition-all duration-500 hover:scale-105 flex flex-col gap-2">
          <div
            className={`flex items-center ${!isSelectedLanguageEnglish && 'flex-row-reverse gap-2'
              }`}
          >
            <HiCurrencyDollar className="text-3xl sm:text-4xl text-nubaqgreen mr-2" />
            <h3 className="text-xl sm:text-2xl font-semibold text-gray-800">
              {isSelectedLanguageEnglish ? 'Our Values' : 'قيمنا'}
            </h3>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 my-2 sm:my-4">
            {values.map((value, index) => (
              <div
                key={index}
                className={`flex items-center gap-2 ${!isSelectedLanguageEnglish && 'flex-row-reverse'
                  }`}
              >
                <GoDotFill className="text-xl sm:text-2xl text-nubaqgreen" />
                <span className="text-sm sm:text-md">{value}</span>
              </div>
            ))}
          </div>
          <div className="text-sm sm:text-md font-semibold">
            {isSelectedLanguageEnglish
              ? 'We have applied all these values over the past 6 years and that is what differentiates us in a competitive market and builds strong, trusting relationships with clients.'
              : '.لقد طبقنا كل هذه القيم على مدى السنوات الست الماضية وهذا ما يميزنا في السوق التنافسية ويبني علاقات قوية مبنية على الثقة مع العملاء'}
          </div>
        </div>
        
        {/* Vision Card */}
        <div className="bg-white md:col-span-2 lg:col-span-1 p-4 sm:p-6 rounded-lg shadow-lg transition-all duration-500 hover:scale-105 flex flex-col gap-2">
          <div
            className={`flex items-center ${!isSelectedLanguageEnglish && 'flex-row-reverse gap-2'
              }`}
          >
            <FaRocket className="text-3xl sm:text-4xl text-nubaqgreen mr-2" />
            <h3 className="text-xl sm:text-2xl font-semibold text-gray-800">
              {isSelectedLanguageEnglish ? 'Our Vision' : 'رؤيتنا'}
            </h3>
          </div>
          <div className="font-semibold text-sm sm:text-md">{vision[1]}</div>
          <div className="text-gray-700 text-sm sm:text-md">{vision[2]}</div>
          <div className="text-gray-700 text-sm sm:text-md">{vision[3]}</div>
          <div className="text-gray-700 text-sm sm:text-md">{vision[4]}</div>
          <div className="font-semibold text-sm sm:text-md">{vision[5]}</div>
          <div className="font-semibold text-sm sm:text-md">{vision[6]}</div>
        </div>
      </div>
    </section>

  );
};

export default MissionNVision;
