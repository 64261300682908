export const Services = {
    servicesEn: {
        buy: {
            text: 'Buy',
            desc: 'Imagine your life in a new home! Take a virtual tour of our featured properties and find your inspiration.'
        },
        sell: {
            text: 'Sell',
            desc: 'Join hundreds of satisfied sellers! Trust us to showcase your home effectively and connect you with serious buyers. '
        },
        rentNew: {
            text: 'Rent New Property',
            desc: 'Your ideal living space is just a click away! Start your journey to renting a property that feels like home.'
        },
        rentOwn: {
            text: 'Rent your own property',
            desc: 'Maximize your rental income! List your property with us and connect with eager tenants looking for their next home.'
        },
        finishing: {
            text: 'Finishing',
            desc: 'Your project deserves the best! Trust us to deliver high-quality finishing services that exceed your expectations.'
        }
    },
    servicesAr: {
        buy: {
            text: 'اشترى',
            desc: 'تخيل حياتك فى منزل جديد! قم بجوله افتراضية فى عقارتنا المميزة و ابحث عن ما يلهمك'
        },
        sell: {
            text: 'بيع وحدتك',
            desc: 'انضم الى مئات البائعين الراضين! ثق بنا لعرض منزلك بفاعلية و ربطك بالمشترين الجادين'
        },
        rentNew: {
            text: 'استأجر عقار جديد',
            desc: 'مساحة المعيشة المثالية الخاصة بك على بعد نقرة واحدة فقط! ابدأ رحلتك لأستئجار عقار يشعرك و كأنة منزل'
        },
        rentOwn: {
            text: 'استأجر وحدتك',
            desc: 'زد من دخلك الإيجارى! قم بإدراج عقارك معنا و تواصل مع مستأجرين متحمسين يبحثون عن منزلهم التالى'
        },
        finishing: {
            text: 'التشطيب',
            desc: 'مشروعك يستحق الأفضل! ثق بنا لتقديم خدمات تشطيب عالى الجودة تتجاوز توقعاتك'
        }
    }
}