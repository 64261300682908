import React, { useContext } from 'react'
import { RiGlobalLine } from 'react-icons/ri'
import { AppContext } from '../AppContext'

const LanguageDropdown = () => {

    const {isSelectedLanguageEnglish, handleLanguageChange} = useContext(AppContext);

    return (
        <div className='flex gap-x-1 items-center justify-center'>
            <button onClick={()=>handleLanguageChange(true)} className={`transition-all duration-500 text-2xl lg:text-lg rounded-lg px-2 ${isSelectedLanguageEnglish ? 'bg-gray-300 text-neutral-800' : ''}`}>EN</button>
            <span className=''>|</span>
            <button onClick={()=>handleLanguageChange(false)} className={`transition-all duration-500 text-2xl lg:text-lg rounded-lg px-2 ${!isSelectedLanguageEnglish ? 'bg-gray-300 text-neutral-800' : ''}`}>AR</button>
            <RiGlobalLine className='text-2xl lg:ml-2'/>
        </div>
    )
}

export default LanguageDropdown
