import React, { useContext, useEffect, useRef, useState } from 'react'
import { BlogsContent } from '../../../data/Blogs';
import { AppContext } from '../../../AppContext';

const Blogs = () => {

  const {isSelectedLanguageEnglish} = useContext(AppContext);
  const blogs = isSelectedLanguageEnglish ? BlogsContent.blogsEn : BlogsContent.blogsAr;

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const handleOpenPDF = (ref) => {
    window.open(ref, '_blank');
  };

  useEffect(() => {
    const currentSection = sectionRef.current; // Store the current ref value in a variable

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(currentSection); // Stop observing once it's visible
        }
      },
      { threshold: 0.2 } // Trigger when 20% of the section is visible
    );

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) observer.unobserve(currentSection); // Use the stored variable in cleanup
    };
  }, []);


  return (
    <section ref={sectionRef} id='blogs' className={`flex flex-col gap-6 items-center justify-center w-full p-8 transition-opacity duration-1000 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
      <div className='text-4xl font-bold text-nubaqgreen' >{isSelectedLanguageEnglish ? 'Blogs' : 'المدونات'}</div>
      <div className='grid grid-cols-1 lg:grid-cols-3 justify-center gap-3'>
        {
          blogs.map((blog) => 
            (
              <button onClick={()=>handleOpenPDF(blog.refrence)} className='w-full flex flex-col p-4 items-center justify-center bg-gray-100 border-2 border-gray-200 shadow-lg rounded-xl font-semibold hover:scale-105 hover:bg-nubaqgreen transition-all duration-500'>
                {blog.title}
              </button>
            )
          )
        }
      </div>
    </section>
  )
}

export default Blogs
