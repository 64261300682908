import Logo from '../assets/Full-Logo2.webp'
import DiscoverImg from '../assets/DiscoverImg.jpg'
import ContactImg from '../assets/ContactImg.jpg'
import AwardsSVG from '../assets/AwardsSVG.png'
import DiscoverPageHero from '../assets/DiscoverPageHero.jpg'
import BuyProperty from '../assets/BuyProperty.jpg';
import image7 from '../assets/image7.jpg';
import image8 from '../assets/image8.jpg';
import image9 from '../assets/image9.jpg';
import image10 from '../assets/image10.jpg';
import image12 from '../assets/image12.jpg';
import image14 from '../assets/image14.jpg';
import Buildyard from '../assets/Buildyard.png';
import MisrItalia from '../assets/MisrItalia.png';
import MountainView from '../assets/MountainView.png';
import Ora from '../assets/Ora.png';
import OrascomDev from '../assets/OrascomDev.png'
import PalmHills from '../assets/PalmHills.png';
import CityEdge from '../assets/CityEdge.png';
import Sobha from '../assets/Sobha.jpg';
import Emaar from '../assets/Emaar.png';
import Damac from '../assets/Damac.png';
import Opia from '../assets/Opia.png';


const images = {
    Logo,
    DiscoverImg,
    ContactImg,
    AwardsSVG,
    DiscoverPageHero,
    BuyProperty,
    image7,
    image8,
    image9,
    image10,
    image12,
    image14,
    Buildyard,
    MountainView,
    MisrItalia,
    Ora,
    OrascomDev,
    PalmHills,
    CityEdge,
    Sobha,
    Emaar,
    Damac,
    Opia
}

export default images;