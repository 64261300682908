import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../AppContext';
import MainLayout from '../../components/MainLayout';
import AllProjects from './containers/AllProjects';
import SEO from '../../components/SEO';

const ProjectsPage = () => {
    const navigate = useNavigate();

    const { projects, isSelectedLanguageEnglish } = useContext(AppContext);
    const [isFiltering, setIsFiltering] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const filteredProjects = projects.filter((project) =>
        project.name.toLowerCase().startsWith(searchInput.toLowerCase())
    )
    return (
        <div>
            <SEO
                title={'Projects | Nubaq'}
                description={'Find your dream home with Nubaq comprehensive real estate services. Explore apartments, villas, and more tailored to your needs.'}
                keywords={'real estate, buy, house, home, apartments, villas, property, properties, sell, finish, rent, duplex, twin house, chalet, north coast, sahel, gouna, sokhna, cairo, new capital, zayed'}
                url={'https://www.nubaq.com/projects'}
                image={'https://www.nubaq.com/assets/Icon.png'}
            />
            <MainLayout>
                <div className='p-4'>
                    <div className='w-full flex items-center justify-center mb-8'>
                        <div className='w-2/3 pt-36'>
                            <input
                                type="text"
                                placeholder={isSelectedLanguageEnglish ? "Enter project name..." : 'أكتب اسم مشروع'}
                                value={searchInput}
                                onFocus={() => setIsFiltering(true)}
                                onChange={(e) => { setSearchInput(e.target.value) }}
                                className={`border-2 border-green-300 p-2 w-full rounded-xl ${!isSelectedLanguageEnglish && 'text-right'}`}
                            />
                            {isFiltering &&
                                <ul className={`bg-white rounded-xl p-2 font-semibold text-[20px]`}>
                                    {filteredProjects.slice(0, 7).map((project) => (
                                        <button onClick={() => { navigate(`/project/${project._id}`) }} key={project._id} className='w-full hover:text-green-400 p-1 transition-all duration-500'>{project.name}</button>
                                    ))}
                                </ul>
                            }
                        </div>
                    </div>
                    <AllProjects />
                </div>
            </MainLayout>
        </div>
    )
}

export default ProjectsPage
