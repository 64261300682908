import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../../AppContext';

const ProjectComponent = ({project}) => {

    const {isSelectedLanguageEnglish} = useContext(AppContext);
    const navigate = useNavigate();

    return (
        <button onClick={() => {window.scrollTo(0,0); navigate(`/project/${project._id}`);}} className='relative rounded-lg flex flex-col border-2 transition-all duration-500 hover:scale-105'>
            <img src={project.logoLink} alt='logo' className='rounded-lg h-2/3 w-full' />
            <div className='w-full bg-gray-100 text-black font-semibold h-1/3 text-lg py-2 flex items-center justify-center'>
                {isSelectedLanguageEnglish ? project.name : project.arabicName}
            </div>
        </button>
    )
}

export default ProjectComponent
