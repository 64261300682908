import React, { useContext } from 'react'
import MainLayout from '../../components/MainLayout'
import UnitDetails from './containers/UnitDetails'
import RelatedUnits from './containers/RelatedUnits'
import { AppContext } from '../../AppContext'
import SEO from '../../components/SEO'

const UnitPage = () => {

  return (
    <div>
      <SEO
        title={'Nubaq | Real Estate'}
        description={'Find your dream home with Nubaq comprehensive real estate services. Explore apartments, villas, and more tailored to your needs.'}
        keywords={'real estate, buy, house, home, apartments, villas, property, properties, sell, finish, rent, duplex, twin house, chalet, north coast, sahel, gouna, sokhna, cairo, new capital, zayed'}
        url={'https://www.nubaq.com/units/:id'}
        image={'https://www.nubaq.com/assets/Icon.png'}
      />
      <MainLayout>
        <UnitDetails />
        <RelatedUnits />
      </MainLayout>
    </div>
  )
}

export default UnitPage
