import AhlySabbour from '../assets/Top Developers/AhlySabbour.jpg';
import AlMarasem from '../assets/Top Developers/AlMarasem.jpg';
import ArkanPalm from '../assets/Top Developers/ArkanPalm.jpg';
import Cred from '../assets/Top Developers/Cred.jpg';
import Dorra from '../assets/Top Developers/Dorra.jpg';
import Emaar from '../assets/Top Developers/Emaar.jpg';
import HassanAllam from '../assets/Top Developers/HassanAllam.jpg';
import HDP from '../assets/Top Developers/HDP.jpg';
import HydePark from '../assets/Top Developers/HydePark.jpg';
import Lavista from '../assets/Top Developers/Lavista.jpg';
import LMD from '../assets/Top Developers/LMD.jpg';
import Marakez from '../assets/Top Developers/Marakez.jpg';
import Maven from '../assets/Top Developers/Maven.jpg';
import MisrItalia from '../assets/Top Developers/MisrItalia.jpg';
import Naia from '../assets/Top Developers/Naia.jpg';
import NewGiza from '../assets/Top Developers/NewGiza.jpg';
import Ora from '../assets/Top Developers/ORA.jpg';
import Orascom from '../assets/Top Developers/Orascom.jpg';
import PalmHills from '../assets/Top Developers/PalmHills.jpg';
import Roya from '../assets/Top Developers/Roya.jpg';
import Sodic from '../assets/Top Developers/Sodic.jpg';
import SomaBay from '../assets/Top Developers/SomaBay.jpg';
import TalaatMoustafa from '../assets/Top Developers/TalaatMoustafa.jpg';
import TatweerMisr from '../assets/Top Developers/TatweerMisr.jpg';
import WaterWay from '../assets/Top Developers/WaterWay.jpg';

export const TopDevs = [
    {
        logo: Emaar,
        name: 'Emaar'
    },
    {
        logo: TalaatMoustafa,
        name: 'Talaat Moustafa'
    },
    {
        logo: NewGiza,
        name: 'New Giza'
    },
    {
        logo: Sodic,
        name: 'Sodic'
    },
    {
        logo: PalmHills,
        name: 'Palm Hills'
    },
    {
        logo: HassanAllam,
        name: 'Hassan Allam'
    },
    {
        logo: Marakez,
        name: 'Marakez'
    },
    {
        logo: HydePark,
        name: 'Hyde Park'
    },
    {
        logo: ArkanPalm,
        name: 'Arkan Palm'
    },
    {
        logo: Lavista,
        name: 'La Vista'
    },
    {
        logo: TatweerMisr,
        name: 'Tatweer Misr'
    },
    {
        logo: Dorra,
        name: 'Dorra'
    },
    {
        logo: SomaBay,
        name: 'Soma Bay'
    },
    {
        logo: AlMarasem,
        name: 'Al Marasem'
    },
    {
        logo: WaterWay,
        name: 'WaterWay'
    },
    {
        logo: Ora,
        name: 'Ora'
    },
    {
        logo: Orascom,
        name: 'Orascom Development'
    },
    {
        logo: Naia,
        name: 'Naia'
    },
    {
        logo: Cred,
        name: 'Cred'
    },
    {
        logo: LMD,
        name: 'LMD'
    },
    {
        logo: Maven,
        name: 'Maven'
    },
    {
        logo: Roya,
        name: 'Roya'
    },
    {
        logo: MisrItalia,
        name: 'Misr Italia'
    },
    {
        logo: HDP,
        name: 'HDP'
    },
    {
        logo: AhlySabbour,
        name: 'Al Ahly Sabbour'
    }
]
