import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import images from '../constants/Images';
import NavBar from './NavBar';
import LanguageDropdown from './LanguageDropdown';
import { AppContext } from '../AppContext';
import { FiMenu } from 'react-icons/fi';
import SideMenu from './SideMenu';
import { BiPhone } from 'react-icons/bi';
import HeaderSocialMedia from './HeaderSocialMedia';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [isFixed, setIsFixed] = useState(false);
  const { isMenuOpened, setIsMenuOpened } = useContext(AppContext);
  const factor = window.innerWidth < 500 ? window.innerHeight*0.7 : window.innerWidth < 1024 ? window.innerHeight*0.6 : 70;
  const handleScroll = () => {
    if (window.scrollY > window.innerHeight - factor) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    // Only add scroll listener if on the home page
    if (location.pathname === '/') {
      window.addEventListener('scroll', handleScroll);
    }

    // Cleanup event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]); // Re-run effect when pathname changes

  // Determine header styles based on path
  const headerStyles = location.pathname === '/'
    ? `${isFixed ? 'bg-black bg-opacity-95' : 'bg-transparent'}`
    : 'bg-black bg-opacity-95'; // Semi-transparent for other pages

  return (
    <div className={`fixed top-0 left-0 z-20 w-full transition-all duration-300 ${headerStyles}`}>
      <div className='px-2 p-0.5 text-sm md:text-lg flex items-center justify-end gap-2 text-white'>
        <HeaderSocialMedia />
        <a 
        href={`tel:+2011145806539`}
        className='flex items-center justify-center pr-3'>
          <BiPhone className='text-lg md:text-2xl text-nubaqgreen'/>
          +201145806539
        </a>
      </div>
      <div className='mx-4 flex justify-between items-center p-2'>
        <div className='col-span-6 flex items-center justify-center'>
          <button onClick={(e) => { navigate('/') }} className='flex items-center justify-center'>
            <img className='w-36 md:w-48' src={images.Logo} alt='Logo' />
          </button>
        </div>
        {
          window.innerWidth > 1024 && (
            <div>
              <NavBar />
            </div>
          )
        }
        {
          window.innerWidth > 1024 && (
            <div className='flex col-span-3 justify-end text-neutral-500'>
              <LanguageDropdown />
            </div>
          )
        }
        {
          window.innerWidth <= 1024 && (
            <button onClick={(e) => setIsMenuOpened(!isMenuOpened)}>
              <FiMenu className='text-white text-2xl' />
            </button>
          )
        }
        <SideMenu />
      </div>
    </div>
  );
};

export default Header;
