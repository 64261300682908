import React, { useState } from 'react';
import { RiCalendar2Line, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { Menu } from '@headlessui/react';

const TimeToCallDropdown = ({ handleInputChange }) => {
  const [timeToCall, setTimeToCall] = useState(''); // Initialize with an empty string
  const [isOpen, setIsOpen] = useState(false);

  const timeToCallOptions = [
    { value: 'Any' },
    { value: '9 Am - 12 Pm' },
    { value: '12 Pm - 3 Pm' },
    { value: '3 Pm - 6 Pm' },
    { value: '6 Pm - 9 Pm' },
    { value: '9 Pm - 12 Am' },
  ];

  const handleSelect = (value) => {
    setTimeToCall(value);
    handleInputChange(value); // Notify the parent component of the selected value
  };

  return (
    <Menu as='div' className='dropdown relative'>
      <Menu.Button
        onClick={() => setIsOpen(!isOpen)}
        className={`dropdown-btn w-full text-left ${isOpen ? 'border-green-300' : ''}`}
      >
        <RiCalendar2Line className='dropdown-icon-primary' />
        <div>
          <div className='text-sm'>{timeToCall}</div>
          <div className='text-sm'>{timeToCall ? '' : 'Choose time to call'}</div>
        </div>
        {isOpen ? (
          <RiArrowUpSLine className='dropdown-icon-secondary' />
        ) : (
          <RiArrowDownSLine className='dropdown-icon-secondary' />
        )}
      </Menu.Button>

      <Menu.Items className='dropdown-menu'>
        {timeToCallOptions.map((time, index) => (
          <Menu.Item
            onClick={() => handleSelect(time.value)} // Call the handleSelect function
            className='cursor-pointer hover:text-green-400 transition-all'
            as='li'
            key={index}
          >
            {time.value}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default TimeToCallDropdown;
