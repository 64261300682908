import React, { useContext, useState } from 'react'
import { AppContext } from '../../AppContext'
import MainLayout from '../../components/MainLayout';
import { useNavigate } from 'react-router-dom'
import AllDevelopers from './containers/AllDevelopers';
import SEO from '../../components/SEO';

const DevelopersPage = () => {

    const navigate = useNavigate();

    const { developers, isSelectedLanguageEnglish } = useContext(AppContext);
    const [isFiltering, setIsFiltering] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    const filteredDevelopers = developers.filter((developer) =>
        developer.name.toLowerCase().startsWith(searchInput.toLowerCase())
    );

    return (
        <div>
            <SEO
                title={'Developers | Nubaq'}
                description={'Find your dream home with Nubaq comprehensive real estate services. Explore apartments, villas, and more tailored to your needs.'}
                keywords={'real estate, buy, house, home, apartments, villas, property, properties, sell, finish, rent, duplex, twin house, chalet, north coast, sahel, gouna, sokhna, cairo, new capital, zayed'}
                url={'https://www.nubaq.com/developers'}
                image={'https://www.nubaq.com/assets/Icon.png'}
            />
            <MainLayout>
                <div className='p-4'>
                    <div className='w-full flex items-center justify-center mb-8'>
                        <div className='w-2/3 pt-36'>
                            <input
                                type="text"
                                placeholder={isSelectedLanguageEnglish ? "Enter developer name..." : 'أكتب اسم مطور'}
                                value={searchInput}
                                onFocus={() => setIsFiltering(true)}
                                onChange={(e) => { setSearchInput(e.target.value) }}
                                className={`border-2 border-green-300 p-2 w-full rounded-xl ${!isSelectedLanguageEnglish && 'text-right'}`}
                            />
                            {isFiltering &&
                                <ul className={`bg-white rounded-xl p-2 font-semibold text-[20px]`}>
                                    {filteredDevelopers.slice(0, 7).map((developer) => (
                                        <button onClick={() => { navigate(`/developer/${developer._id}`) }} key={developer._id} className='w-full hover:text-green-400 p-1 transition-all duration-500'>{developer.name}</button>
                                    ))}
                                </ul>
                            }
                        </div>
                    </div>
                    <AllDevelopers />
                </div>
            </MainLayout>
        </div>
    )
}

export default DevelopersPage
