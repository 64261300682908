import React, { useState, useContext } from 'react'
import { GiTrophy } from 'react-icons/gi'
import { AppContext } from '../AppContext'

const AwardComponent = ({ award }) => {
  const { isSelectedLanguageEnglish } = useContext(AppContext);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  return (
    <div className="flex flex-col gap-2 p-4 rounded-xl shadow-lg border border-nubaqgreen">
      {/* Title */}
      <div
        className={`w-full text-xl text-center font-semibold flex items-center gap-2 ${!isSelectedLanguageEnglish && "flex-row-reverse"
          }`}
      >
        <div className="shrink-0">
          <GiTrophy className="text-3xl text-[#daa520]" />
        </div>
        <div className="flex-1">{award.title}</div>
      </div>

      {/* Description */}
      <div className="text-center">
        <p
          className={`${!isDescriptionExpanded
            ? "line-clamp-5 sm:line-clamp-none"
            : "line-clamp-none"
            }`}
        >
          {award.description}
        </p>
        {/* See More/See Less Button */}
        <button
          onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
          className="text-blue-500 hover:underline mt-2 sm:hidden"
        >
          {isDescriptionExpanded ? "See Less" : "See More"}
        </button>
      </div>

      {/* Presenter Section */}
      <div
        className={`flex flex-wrap items-center justify-center lg:justify-start ${!isSelectedLanguageEnglish && "flex-row-reverse"
          } gap-2`}
      >
        <img className="w-28 h-28" src={award.presenterLogo} alt="Presenter Logo" />
        <span className="font-bold text-lg">{award.presenter}</span>
      </div>
    </div>
  )
}

export default AwardComponent
