import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../AppContext';

const NavBar = () => {

    const {isSelectedLanguageEnglish} = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className=' text-lg text-white flex justify-center pt-0 p-1 '> {/* flex justify-between pt-4 border-b-2 */}
            <div className={`flex ${!isSelectedLanguageEnglish && 'flex-row-reverse'} justify-center items-center gap-3`}>
                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('video')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }}
                    className='group relative overflow-hidden  border-white w-1/3 h-full text-center p-2 '> {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold '>{isSelectedLanguageEnglish ? 'Home' : 'الرئيسيه'}</span>
                    <div className={`h-full rounded-full bg-nubaqgreen absolute z-1 bottom-0 w-0 group-hover:w-full transition-all duration-500 ${isSelectedLanguageEnglish ? 'left-0' : 'right-0' }`}></div>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('aboutus')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }} className='group relative overflow-hidden  border-white h-full text-center p-2 w-1/3'> {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold whitespace-nowrap'>{isSelectedLanguageEnglish ? 'Our Story' : 'قصتنا'}</span>
                    <div className={`h-full rounded-full bg-nubaqgreen absolute z-1 bottom-0 w-0 group-hover:w-full transition-all duration-500 ${isSelectedLanguageEnglish ? 'left-0' : 'right-0' }`}></div>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('partners')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }} className='group relative overflow-hidden  border-white w-1/3 h-full text-center p-2'> {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold'>{isSelectedLanguageEnglish ? 'Partners' : 'شركائنا'}</span>
                    <div className={`h-full rounded-full bg-nubaqgreen absolute z-1 bottom-0 w-0 group-hover:w-full transition-all duration-500 ${isSelectedLanguageEnglish ? 'left-0' : 'right-0' }`}></div>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('discover')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }} className='group relative overflow-hidden border-white w-1/3 h-full text-center p-2'> {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold'>{isSelectedLanguageEnglish ? 'Services' : 'خدماتنا'}</span>
                    <div className={`h-full rounded-full bg-nubaqgreen absolute z-1 bottom-0 w-0 group-hover:w-full transition-all duration-500 ${isSelectedLanguageEnglish ? 'left-0' : 'right-0' }`}></div>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('awards')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }} className='group relative overflow-hidden  border-white w-1/3 h-full text-center p-2'> {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold'>{isSelectedLanguageEnglish ? 'Awards' : 'جوائزنا'}</span>
                    <div className={`h-full rounded-full bg-nubaqgreen absolute z-1 bottom-0 w-0 group-hover:w-full transition-all duration-500 ${isSelectedLanguageEnglish ? 'left-0' : 'right-0' }`}></div>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('blogs')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }} className='group relative overflow-hidden border-white w-1/3 h-full text-center p-2'>  {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold'>{isSelectedLanguageEnglish ? 'Blogs' : 'المدونات'}</span>
                    <div className={`h-full rounded-full bg-nubaqgreen absolute z-1 bottom-0 w-0 group-hover:w-full transition-all duration-500 ${isSelectedLanguageEnglish ? 'left-0' : 'right-0' }`}></div>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('contactus')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }} className='group relative overflow-hidden  border-white  h-full text-center p-2 w-72'>
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold whitespace-nowrap'>{isSelectedLanguageEnglish ? 'Contact us' : 'اتصل بنا'}</span>
                    <div className={`h-full rounded-full bg-nubaqgreen absolute z-1 bottom-0 w-0 group-hover:w-full transition-all duration-500 ${isSelectedLanguageEnglish ? 'left-0' : 'right-0' }`}></div>
                </button>
            </div>
        </div>
    )
}

export default NavBar
