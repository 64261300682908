import React from 'react'
import MainLayout from '../../components/MainLayout'
import Form from '../../components/Form'
import SEO from '../../components/SEO'

const SellPage = () => {
  return (
    <div>
      <SEO
        title={'Sell | Nubaq'}
        description={'Find your dream home with Nubaq comprehensive real estate services. Explore apartments, villas, and more tailored to your needs.'}
        keywords={'real estate, buy, house, home, apartments, villas, property, properties, sell, finish, rent, duplex, twin house, chalet, north coast, sahel, gouna, sokhna, cairo, new capital, zayed'}
        url={'https://www.nubaq.com/sell'}
        image={'https://www.nubaq.com/assets/Icon.png'}
      />
      <MainLayout>
        <Form rent={false} />
      </MainLayout>
    </div>
  )
}

export default SellPage
