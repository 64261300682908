import React, { useContext } from 'react'
import { AppContext } from '../../../AppContext';
import { Partners as partner } from '../../../data/Partners';
import PartnerComponent from '../../../components/PartnerComponent';

const Partners = () => {

    const { isSelectedLanguageEnglish } = useContext(AppContext);
    const partnersData = isSelectedLanguageEnglish ? partner.partnersEn : partner.partnersAr;

    return (
        <section
            id="partners"
            className={`flex flex-col gap-6 items-center justify-center w-full p-8 transition-opacity duration-1000 ease-in-out`}
        >
            {/* Section Title */}
            <div className="text-3xl sm:text-4xl font-bold text-nubaqgreen">
                {isSelectedLanguageEnglish ? 'Partners' : 'شركائنا'}
            </div>

            {/* Description */}
            <div className="text-md sm:text-md text-center max-w-3xl">
                {isSelectedLanguageEnglish
                    ? 'Nubaq Properties proudly collaborates with some of the world’s most prestigious property developers, including Damac Properties, Emaar Dubai, Sobha Realty, and Buildyard. These partnerships allow us to showcase a range of luxury residential and commercial properties, from high-rise towers to exclusive villas. Our alliances with these industry leaders provide our clients exclusive access to high-quality developments, reflecting the pinnacle of modern living.'
                    : 'تفتخر شركة نوباك العقارية بالتعاون مع بعض من أرقى مطوري العقارات في العالم، بما في ذلك داماك العقارية, إعمار دبي, صبحا العقارية، وساحة البناء تتيح لنا هذه الشراكات عرض مجموعة من العقارات السكنية والتجارية الفاخرة، من الأبراج الشاهقة إلى الفيلات الحصرية. توفر تحالفاتنا مع رواد الصناعة هؤلاء لعملائنا إمكانية الوصول الحصري إلى مشاريع عالية الجودة تعكس قمة الحياة العصرية'}
            </div>

            {/* Partners Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 w-full ">
                <PartnerComponent partner={partnersData.damac} />
                <PartnerComponent partner={partnersData.emaar} />
                <PartnerComponent partner={partnersData.sobha} />
                <PartnerComponent partner={partnersData.buildyard} />
            </div>
        </section>

    )
}

export default Partners
