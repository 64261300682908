export const AboutUsContent = {
    aboutUsEn : {
        1 : 'Founded by Amr Al-Habashy, Nubaq for real estate has its roots in Opia for real estate, which operated from 2018 until 2022 before transforming into the Nubaq brand. Building on Opia’s legacy of integrity and client satisfaction, Nubaq continues to redefine real estate services with a focus on excellence, transparency, and innovation.',
        2 : 'At Nubaq, we are dedicated to providing top-tier brokerage services that address the diverse financial needs of our clients. We do work with top developers in Egypt and the UAE to offer unparalleled access to premier real estate opportunities. Our experienced team of professionals delivers reliable insights, strategic advice, and seamless trade execution, empowering clients to make informed decisions with confidence. Whether you are an institutional investor or an individual buyer, we are here to guide you through the complexities of the real estate market.',
        3 : 'Our presence as Nubaq is founded on the values of transparency, trust, and innovation. We strive to build lasting relationships by offering tailored services that drive success. By equipping you with the tools and knowledge you need, we’re here to help you "Cash Your Life Back" and achieve your real estate and investment goals.'
    },
    aboutUsAr : {
        1 : 'تأسست شركة نوباك للعقارات على يد عمرو الحبشي، وتعود جذورها إلى شركة أوبيا للعقارات، والتي عملت من عام 2018 حتى عام 2022 قبل أن تتحول إلى علامة نوباك التجارية. وبناءً على إرث أوبيا من النزاهة وإرضاء العملاء، تواصل نوباك إعادة تعريف الخدمات العقارية مع التركيز على التميز والشفافية والابتكار.',
        2 : 'في نوباك، نحن ملتزمون بتقديم خدمات الوساطة من الدرجة الأولى التي تلبي الاحتياجات المالية المتنوعة لعملائنا. نحن نعمل مع كبار المطورين في مصر والإمارات العربية المتحدة لتقديم وصول لا مثيل له إلى فرص العقارات المتميزة. يقدم فريقنا من المحترفين ذوي الخبرة رؤى موثوقة ونصائح استراتيجية وتنفيذ تداول سلس، مما يمكّن العملاء من اتخاذ قرارات مستنيرة بثقة. سواء كنت مستثمرًا مؤسسيًا أو مشتريًا فرديًا، فنحن هنا لإرشادك عبر تعقيدات سوق العقارات.',
        3 : 'يعتمد وجودنا في Nubaq على قيم الشفافية والثقة والابتكار. نسعى جاهدين لبناء علاقات دائمة من خلال تقديم خدمات مخصصة تدفعك إلى النجاح. من خلال تزويدك بالأدوات والمعرفة التي تحتاجها، نحن هنا لمساعدتك على "استعادة أموالك" وتحقيق أهدافك العقارية والاستثمارية.'
    }
}