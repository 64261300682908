import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import LanguageDropdown from './LanguageDropdown';
import { FiMenu } from 'react-icons/fi';

const SideMenu = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const { isSelectedLanguageEnglish, setIsMenuOpened, isMenuOpened } = useContext(AppContext);

    return (
        <div className={`fixed top-0 right-0 w-0 h-screen bg-black bg-opacity-95 text-white text-[24px] md:text-[30px] transition-all duration-500 ${isMenuOpened && 'w-2/3'}`}>
            <div className={`flex flex-col w-full h-5/6 justify-center items-center gap-2 pb-4 px-2`}>
                <div className='w-full text-right p-2'>
                    <button onClick={(e) => setIsMenuOpened(!isMenuOpened)}>
                        <FiMenu className='text-white text-2xl' />
                    </button>
                </div>
                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('video')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                    setIsMenuOpened(false);
                }}
                    className='group relative overflow-hidden  border-white w-full h-full text-center p-2 '> {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold '>{isSelectedLanguageEnglish ? 'Home' : 'الرئيسيه'}</span>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('aboutus')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                    setIsMenuOpened(false);
                }} className='group relative overflow-hidden  border-white h-full text-center p-2 w-full'> {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold whitespace-nowrap'>{isSelectedLanguageEnglish ? 'Our Story' : 'قصتنا'}</span>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('discover')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                    setIsMenuOpened(false);
                }} className='group relative overflow-hidden border-white w-full h-full text-center p-2'> {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold'>{isSelectedLanguageEnglish ? 'Services' : 'خدماتنا'}</span>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('partners')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                    setIsMenuOpened(false);
                }} className='group relative overflow-hidden  border-white w-full h-full text-center p-2'> {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold'>{isSelectedLanguageEnglish ? 'Partners' : 'شركائنا'}</span>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('awards')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                    setIsMenuOpened(false);
                }} className='group relative overflow-hidden  border-white w-full h-full text-center p-2'> {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold'>{isSelectedLanguageEnglish ? 'Awards' : 'جوائزنا'}</span>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('blogs')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                    setIsMenuOpened(false);
                }} className='group relative overflow-hidden border-white w-full h-full text-center p-2'>  {/*w-full border-r-2 */}
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold'>{isSelectedLanguageEnglish ? 'Blogs' : 'المدونات'}</span>
                </button>

                <button onClick={(e) => {
                    if (location.pathname !== '/') {
                        navigate('/');
                    }
                    setTimeout(() => {
                        document.getElementById('contactus')?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                    setIsMenuOpened(false);
                }} className='group relative overflow-hidden  border-white  h-full text-center p-2 w-full'>
                    <span className='relative z-10 group-hover:text-neutral-900 transition-colors duration-500 bg-transparent w-full font-semibold whitespace-nowrap'>{isSelectedLanguageEnglish ? 'Contact us' : 'اتصل بنا'}</span>
                </button>
                <div className={`relative overflow-hidden h-full w-full flex items-center justify-center`}>
                    <LanguageDropdown />
                </div>
            </div>
        </div>
    )
}

export default SideMenu
