import React, { useContext } from 'react'
import { AppContext } from '../AppContext'

const PartnerComponent = (partner) => {

    const { isSelectedLanguageEnglish } = useContext(AppContext);

    return (
        <div className={`flex flex-col border-2 border-nubaqgreen p-4 shadow-lg rounded-xl`}>
            <div className='w-full items-center justify-center flex'>
                <img className='rounded-lg h-36' src={partner.partner.logo} />
            </div>
            <div className='flex items-center justify-center font-bold text-2xl p-2'>
                {partner.partner.name}
            </div>
            <div className={`p-4 text-gray-700 ${!isSelectedLanguageEnglish && 'text-right'}`}>
                {partner.partner.description}
            </div>
            {
                partner.partner.points.map((point) => (
                    <div className={`flex gap-1 ${!isSelectedLanguageEnglish && 'flex-row-reverse text-right'}`}>
                        <span>•</span>
                        <span>{point}</span>
                    </div>
                ))
            }
        </div>
    )
}

export default PartnerComponent
