import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaLocationDot, FaLocationArrow, FaChevronLeft, FaChevronRight, FaBuilding } from 'react-icons/fa6';
import { BsBuildingsFill } from 'react-icons/bs';
import { BiArea } from 'react-icons/bi';
import { IoBed } from 'react-icons/io5';
import { FcCalendar } from "react-icons/fc";
import { FcDebt } from "react-icons/fc";
import { RiTeamFill } from "react-icons/ri";
import { GrNotes } from "react-icons/gr";
import TimeToCallDropdown from '../../../components/TimeToCallDropdown';
import axios from 'axios';
import { AppContext } from '../../../AppContext';
import { FaBath } from 'react-icons/fa';

const UnitDetails = () => {
  const location = useLocation();
  const { unit } = location.state;

  const { isSelectedLanguageEnglish, getDeveloperLogo, getProjectLogo } = useContext(AppContext);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const totalImages = unit.images.length;

  // Function to handle moving to the previous image
  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalImages - 1 : prevIndex - 1));
  };

  // Function to handle moving to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === totalImages - 1 ? 0 : prevIndex + 1));
  };

  const handleTimeToCallChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      timeToCall: value,
    }))
  }

  // State for the contact form
  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientNumber: '',
    unitId: unit._id,
    message: '',
    status: 'Open',
    requestType: 'Unit Contact',
    timeToCall: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  let circleCommonClasses = 'h-2.5 w-2.5 bg-green-400   rounded-full';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios.post('https://legendary-cucurucho-9bea38.netlify.app/.netlify/functions/api/api/contact-requests', { params: { formData } })
      .then((response) => {
        alert("Your request had submitted successfully, we will contact you soon.")
        clearForm();
      })
      .catch((err) => {
        alert("Error submitting your request, please try again...")
      });
    setLoading(false);
    console.log(formData);
  };

  const clearForm = () => {
    setFormData({
      clientName: '',
      clientEmail: '',
      clientNumber: '',
      unitId: unit._id,
      message: '',
      status: 'Open',
      requestType: 'Unit Contact',
    })
  }

  const ArabicPropertyTypeOptions = [
    { value: 'Any', label: 'الكل' },
    { value: 'Studio', label: 'استوديو' },
    { value: 'Apartment', label: 'شقة' },
    { value: 'Doublex', label: 'دوبلكس' },
    { value: 'Twin House', label: 'توين هاوس' },
    { value: 'Town House', label: 'تاون هاوس' },
    { value: 'Stand Alone', label: 'فيلا منفصلة' },
    { value: 'Chalet', label: 'شالية' },
    { value: 'Office', label: 'مكتب' },
    { value: 'Clinic', label: 'عيادة' },
    { value: 'Retail', label: 'تجارى' },
  ];

  return (
    <div className="flex flex-col bg-gray-50 pt-36">
      <div className="flex justify-center p-3">
        <span className="text-4xl font-bold text-center text-shadow-lg">{isSelectedLanguageEnglish ? unit.title : unit.arabicTitle}</span>
      </div>

      <hr className="border-t border-gray-400 my-3" />
      {/* Address */}
      <span className="text-2xl w-full gap-2 flex items-center justify-center p-3 mb-4">
        <FaLocationDot className="text-green-400 " />
        <span className="font-semibold text-center">{isSelectedLanguageEnglish ? unit.address : unit.arabicAddress}</span>
      </span>
      <div className='flex flex-wrap'>
        {/* Image Section with Thumbnails */}
        <div className="relative lg:w-2/3 w-full p-2 pb-0">
          {/* Main Image */}
          <div className="w-full mb-4 relative">
            <img
              className="w-full max-h-screen shadow-md rounded-3xl"
              src={unit.images[currentIndex]}
              alt={`Unit Image ${currentIndex + 1}`}
            />
            {/* Left Arrow for previous image */}
            <button
              className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white bg-green-500 p-2 rounded-full hover:bg-gray-700"
              onClick={prevImage}
            >
              <FaChevronLeft size={20} />
            </button>
            {/* Right Arrow for next image */}
            <button
              className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white bg-green-500 p-2 rounded-full hover:bg-gray-700"
              onClick={nextImage}
            >
              <FaChevronRight size={20} />
            </button>
          </div>

          {/* Thumbnail Gallery */}
          <div className="flex flex-wrap gap-2">
            {unit.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className={`w-20 h-20 object-cover cursor-pointer shadow-md rounded ${currentIndex === index ? 'ring-2 ring-green-400' : ''}`}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>

          <div className="flex flex-col justify-center gap-x-5 text-[19px] m-4 p-2 ">
            {/* Overview Section with space and line */}
            <div className={`flex justify-between ${!isSelectedLanguageEnglish && 'flex-row-reverse'} `}>
              <div className='flex items-center'>
                <span className="text-xl">{isSelectedLanguageEnglish ? 'Overview' : 'نظره عامه'}</span>
              </div>
              <div className='flex items-center'>
                <span className="font-semibold text-2xl p-3 rounded flex gap-2">{isSelectedLanguageEnglish ? 'Price' : 'السعر'}: {unit.price} {isSelectedLanguageEnglish ? 'EGP' : 'ج.م'}</span>
              </div>
            </div>
            <div className="my-2">
              <hr className="border-t-2 border-gray-300" />
            </div>

            {/* Property Features listed below the line */}
            <div className='flex flex-wrap justify-between gap-4 '>
              {/* Property Type */}
              <div className="flex flex-col whitespace-nowrap">
                <div className='flex items-center'>
                  <FaBuilding className="text-xl text-nubaqgreen mr-2"></FaBuilding>
                  <span className='font-semibold' style={{ color: "black" }}>
                    {isSelectedLanguageEnglish
                      ? unit.propertyType
                      : ArabicPropertyTypeOptions.find((type) => type.value === unit.propertyType)?.label || 'غير محدد'}
                  </span>
                </div>
                <span className='text-gray-400'>{isSelectedLanguageEnglish ? 'Property Type' : 'نوع الوحده'}</span>
              </div>

              {/* Bedrooms */}
              <div>
                <div className="items-center whitespace-nowrap">
                  <span className='flex items-center'>
                    <IoBed className="text-xl text-nubaqgreen mr-2" />
                    <span className='font-semibold' style={{ color: "black" }}>{unit.bedrooms}</span>
                  </span>
                  <span className='text-gray-400'>{isSelectedLanguageEnglish ? 'Rooms' : 'الغرف'}</span>
                </div>
              </div>

              {/* Bathrooms */}
              <div>
                <div className="items-center whitespace-nowrap">
                  <span className='flex items-center'>
                    <FaBath className="text-xl text-nubaqgreen mr-2" />
                    <span className='font-semibold' style={{ color: "black" }}>{unit.bathroom}</span>
                  </span>
                  <span className='text-gray-400'>{isSelectedLanguageEnglish ? 'Bathrooms' : 'الحمامات'}</span>
                </div>
              </div>

              {/* Area */}
              <div>
                <div className="items-center whitespace-nowrap">
                  <span className='flex items-center'>
                    <BiArea className="text-xl text-nubaqgreen mr-2" />
                    <span className='font-semibold' style={{ color: "black" }}>{unit.area}</span>
                  </span>
                  <span className='text-gray-400'>{isSelectedLanguageEnglish ? 'Area (m²)' : '(m²) المساحه'}</span>
                </div>
              </div>

              {/* Location */}
              <div>
                <div className="items-center whitespace-nowrap">
                  <span className='flex items-center'>
                    <FaLocationArrow className="text-xl text-nubaqgreen mr-2" />
                    <span className='font-semibold' style={{ color: "black" }}>{isSelectedLanguageEnglish ? unit.location : unit.arabicLocation}</span>
                  </span>
                  <span className='text-gray-400'>{isSelectedLanguageEnglish ? 'Location' : 'الموقع'}</span>
                </div>
              </div>

              {/* Project */}
              <div>
                <div className="items-center whitespace-nowrap">
                  <span className='flex items-center'>
                    <BsBuildingsFill className="text-xl text-nubaqgreen mr-2" />
                    <span className='font-semibold' style={{ color: "black" }}>{isSelectedLanguageEnglish ? unit.project : unit.arabicProject}</span>
                  </span>
                  <span className='text-gray-400'>{isSelectedLanguageEnglish ? 'Project' : 'المشروع'}</span>
                </div>
              </div>

              {/* Developer */}
              <div>
                <div className="items-center whitespace-nowrap">
                  <span className='flex items-center'>
                    {/* <BsBuildingsFill className="text-xl text-nubaqgreen mr-2" /> */}
                    <RiTeamFill className="text-xl text-nubaqgreen mr-2" />
                    <span className='font-semibold' style={{ color: "black" }}>{isSelectedLanguageEnglish ? unit.developer : unit.arabicDeveloper}</span>
                  </span>
                  <span className='text-gray-400'>{isSelectedLanguageEnglish ? 'Developer' : 'المطور'}</span>
                </div>
              </div>


            </div>

            {/* date and payment at end of page */}
            <div className={`flex flex-wrap py-8 justify-between gap-4 text-lg ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
              <span className={`flex items-center gap-y-1 gap-x-2 ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                <FcCalendar className='text-2xl' />
                <span className={`font-semibold flex ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`} ><div>{isSelectedLanguageEnglish ? 'Delivery Date : ' : ' : تاريخ الاستلام'}</div> <div>{unit.deliveryDate}</div></span>
              </span>

              <span className={`flex items-center gap-y-1 gap-x-2 ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                <FcDebt className='text-2xl' />
                <span className='font-semibold' >{isSelectedLanguageEnglish ? 'Payment Plan : ' : 'نظام السداد:'} {isSelectedLanguageEnglish ? unit.paymentPlan : unit.arabicPaymentPlan}</span>
              </span>
              {
                unit.downPayment && (
                  <span className={`flex items-center gap-y-1 gap-x-2 ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                    <span className='font-semibold' >{isSelectedLanguageEnglish ? 'Downpayment:' : 'المقدم:'} {unit.downPayment}</span>
                  </span>
                )
              }
              {
                unit.quarterInstallment && (
                  <span className={`flex items-center gap-y-1 gap-x-2 ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                    <span className='font-semibold' >{isSelectedLanguageEnglish ? 'Quarter Installment:' : 'القسط الربع سنوى:'} {unit.quarterInstallment}</span>
                  </span>
                )
              }
              {
                unit.installmentsYears && (
                  <span className={`flex items-center gap-y-1 gap-x-2 ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                    <span className='font-semibold' >{isSelectedLanguageEnglish ? 'Installments Years:' : 'سنوات الأقساط:'} {unit.installmentsYears}</span>
                  </span>
                )
              }
            </div>
            {/* Additional Features Section with space and line */}
            {
              (unit.floor > 0 || unit.landArea > 0 || unit.roof > 0 || unit.garden > 0) &&
              <div className="my-10">
                <div className={`w-full ${!isSelectedLanguageEnglish && 'text-right'}`} >
                  <span className="text-xl">{isSelectedLanguageEnglish ? 'Additional Features' : 'خصائص اضافيه'}</span>
                  <div className="my-4">
                    <hr className="border-t-2 border-gray-300" />
                  </div>
                </div>
                <div className="m-4 flex justify-center mt-0">
                  <div className="flex flex-col md:flex-row justify-between mt-2 gap-x-8 items-center">
                    {unit.landArea > 0 && (
                      <div className="flex items-center text-[19px] font-semibold">
                        <span>{isSelectedLanguageEnglish ? 'Land Area (m²):' : '(m²) مساحه الارض:'} {unit.landArea}</span>
                      </div>
                    )}
                    {unit.roof > 0 && (
                      <div className="flex items-center text-[19px] font-semibold">
                        <span>{isSelectedLanguageEnglish ? 'Roof (m²):' : '(m²) الرووف:'} {unit.roof}</span>
                      </div>
                    )}
                    {unit.garden > 0 && (
                      <div className="flex items-center text-[19px] font-semibold">
                        <span>{isSelectedLanguageEnglish ? 'Garden (m²):' : '(m²) الحديقه:'} {unit.garden}</span>
                      </div>
                    )}
                    {unit.floor > 0 && (
                      <div className="flex items-center text-[19px] font-semibold">
                        <span>{isSelectedLanguageEnglish ? 'Floor:' : 'الدور:'} {unit.floor}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }
            {/* Description */}
            <div>
              <div className={`items-center w-full ${!isSelectedLanguageEnglish && 'text-right'}`}>
                <span className='text-gray-400 mx-2'>{isSelectedLanguageEnglish ? 'Description' : 'الوصف'}</span>
                <div className={`flex gap-2 items-center ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                  <div><GrNotes className="text-xl text-nubaqgreen" /></div>
                  {/* <BsBuildingsFill className="text-xl text-nubaqgreen mr-2" /> */}
                  <span className=' ml-2' style={{ color: "black" }}>{isSelectedLanguageEnglish ? unit.description : unit.arabicDescription}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Unit Details */}
        <div className="flex flex-col gap-2 p-3 lg:w-1/3 w-full">
          <div className='flex justify-center font-semibold text-lg items-center gap-x-5'>
            {/* developer pic 1st */}
            <div>
              <div className='flex items-center gap-2'><img className='w-14 h-14 rounded-full' alt='Logo' src={getDeveloperLogo(unit.developer)} />
              </div>
              {/* developer name below pic */}
              {isSelectedLanguageEnglish ? unit.developer : unit.arabicDeveloper}
            </div>

            {/* project pic */}
            <div>
              <div className='flex items-center gap-2'> <img className='w-14 h-14 rounded-full' alt='Logo' src={getProjectLogo(unit.project)} />
              </div>
              {isSelectedLanguageEnglish ? unit.project : unit.arabicProject}
            </div>
          </div>

          {/* Contact Form */}
          <div className="relative flex flex-col justify-center mt-5 bg-nubaqgreen p-3 rounded-3xl">
            {loading &&
              <div className='absolute z-10  bg-opacity-50 bg-black w-full h-full flex items-center justify-center'>
                <div className='flex items-center justify-center rounded-full h-20 w-20 bg-white'>
                  <div className='flex items-center justify-center w-full h-screen-minus-245'>
                    <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
                    <div
                      className={`${circleCommonClasses} mr-1 animate-bounce200`}
                    ></div>
                    <div className={`${circleCommonClasses} animate-bounce400`}></div>
                  </div>
                </div>
              </div>
            }
            <span className={`font-semibold text-2xl ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'Contact Us' : 'ارسل لنا'}</span>
            <form onSubmit={handleSubmit} className="flex flex-col gap-y-4 mt-4">
              <input
                type="text"
                name="clientName"
                value={formData.clientName}
                onChange={handleChange}
                placeholder="Name"
                required
                className="border border-gray-300 rounded-lg p-2"
              />
              <input
                type="email"
                name="clientEmail"
                value={formData.clientEmail}
                onChange={handleChange}
                placeholder="Email"
                required
                className="border border-gray-300 rounded-lg p-2"
              />
              <input
                type="tel" // Changed type to 'tel' for better mobile number input
                name="clientNumber"
                value={formData.clientNumber}
                onChange={handleChange}
                placeholder="Mobile Number"
                required
                className="border border-gray-300 rounded-lg p-2"
              />
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Message"
                required
                className="border border-gray-300 rounded-lg p-2 h-60"
              />
              <div className='bg-white rounded-lg border border-gray-300'>
                <TimeToCallDropdown handleInputChange={handleTimeToCallChange} />
              </div>
              <button
                type="submit"
                className="bg-black text-white font-bold py-2  hover:bg-white hover:text-black transition duration-300 rounded-3xl"
              >
                {isSelectedLanguageEnglish ? 'Send Message' : 'ارسال'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitDetails;