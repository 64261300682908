import React, { useState, useEffect, useContext } from 'react';
import MainLayout from '../../components/MainLayout';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import ProjectComponent from '../DiscoverPage/containers/ProjectComponent';
import UnitComponent from '../../components/UnitComponent';
import { MdLocationPin } from 'react-icons/md';
import SEO from '../../components/SEO';

const DeveloperPage = () => {
  const { getDeveloperById, getProjectsFromDeveloper, getUnitsFromDeveloper, loading: contextLoading, isSelectedLanguageEnglish } = useContext(AppContext);
  const { id } = useParams();

  const [developer, setDeveloper] = useState(null);
  const [projects, setProjects] = useState([]);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDeveloper = async () => {
      if (contextLoading) return; // Wait for context to finish loading
      try {
        const developerData = await getDeveloperById(id);
        if (!developerData) {
          throw new Error('Developer not found');
        }
        const projectsData = await getProjectsFromDeveloper(developerData.name);
        if (!projectsData) {
          throw new Error("Error fetching projects data")
        }
        const unitsData = await getUnitsFromDeveloper(developerData.name);
        if (!unitsData) {
          throw new Error("Error fetching units data")
        }
        setDeveloper(developerData);
        setProjects(projectsData);
        setUnits(unitsData);
      } catch (err) {
        setError(err.message || 'Failed to fetch developer data.');
      } finally {
        setLoading(false);
      }
    };

    fetchDeveloper();
  }, [getDeveloperById, getProjectsFromDeveloper, getUnitsFromDeveloper, id, contextLoading]);

  if (contextLoading || loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <SEO
        title={`${developer.name} | Nubaq`}
        description={'Find your dream home with Nubaq comprehensive real estate services. Explore apartments, villas, and more tailored to your needs.'}
        keywords={'real estate, buy, house, home, apartments, villas, property, properties, sell, finish, rent, duplex, twin house, chalet, north coast, sahel, gouna, sokhna, cairo, new capital, zayed'}
        url={'https://www.nubaq.com/developers/:id'}
        image={'https://www.nubaq.com/assets/Icon.png'}
      />
      <MainLayout>
        <div className='pt-40 w-full flex flex-col gap-4'>
          <div className='w-full flex flex-col lg:flex-row items-center justify-center '>
            <div className='flex flex-col gap-4 w-full justify-center items-center px-4'>
              <h1 className='font-semibold text-center text-[20px] md:text-[26px] lg:text-[32px]'>{isSelectedLanguageEnglish ? developer.name : developer.arabicName}</h1>
              <img className='w-1/2 lg:w-full max-w-[600px]' src={developer.logoLink} alt='Developer Logo' />
            </div>
            <div className='flex flex-col'>
              {
                developer.zones && (
                  <div className='flex flex-wrap w-full items-center justify-center gap-2'>
                    {
                      developer.zones.map((zone) => (
                        <div className='px-4 py-2 bg-nubaqgreen rounded-full flex items-center justify-center gap-1'><MdLocationPin className='text-white' />{zone}</div>
                      ))
                    }
                  </div>
                )
              }
              <h1 className='p-4 text-center lg:text-left sm:text-[10px] md:text-[20px] lg:text-[24px]'>{isSelectedLanguageEnglish ? developer.description : developer.arabicDescription}</h1>
            </div>
          </div>
          <div className='m-2 border-2 border-nubaqgreen rounded-xl p-4 flex flex-col'>
            <div className={`text-[24px] font-semibold text-nubaqgreen ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'Projects' : 'المشاريع'}</div>
            <div className='p-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
              {projects.map((project) => (
                <ProjectComponent project={project} />
              ))}
            </div>
          </div>
          <div className='m-2 border-2 border-nubaqgreen rounded-xl p-4 flex flex-col'>
            <div className={`text-[24px] font-semibold text-nubaqgreen ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'Units' : 'الوحدات'}</div>
            <div className='p-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
              {units.map((unit) => (
                <UnitComponent unit={unit} />
              ))}
            </div>
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default DeveloperPage;
