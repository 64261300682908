import images from "../constants/Images"

export const Awards = {
    awardsEn: {
        1: {
            title: 'Top 10 achievers in IL Bosco',
            presenter: 'Misr Italia properties',
            presenterLogo: images.MisrItalia,
            year: '2016',
            description: `This award recognizes that we have achieved top 10 sales in the first launch in IL Bosco in New Capital. At Opia, we are honored to receive this recognition for our commitment to this launch because of it is masterplanned by world-renowned CallisonRTKL in collaboration with DMA, IL BOSCO unveils a naturally-inspired concept of eco-friendly living to dictate a one-of-a-kind living experience where greenery and well-being abound on 200 feddans. The project is gracefully poised within the New Capital’s upscale residential district R7 to pay tribute to sustainable development while harmoniously marrying modern-day function with aesthetic form. Designed with varied contemporary lifestyles in mind, IL BOSCO is home to a signature collection of living options, spanning elegantly appointed villas to spacious modern apartments where nature lives vertically, as well as indoors. Living at any of IL BOSCO’s five phases is connecting with nature in every possible form while reaping the benefits of a healthy lifestyle in the heart of Egypt’s New Capital.`
        },
        2: {
            title: 'Top 5 achievers in New Alamein Towers',
            presenter: 'City Edge Developments',
            presenterLogo: images.CityEdge,
            year: '2018',
            description: `Awarded to Opia properties for real estate, this accolade highlights outstanding sales performance and exceptional client service. This recognition showcases our team’s dedication to excellence and their ability to build lasting relationships with clients.
North Edge Towers are the pioneering pillars of the New Alamein waterfront with a mission to begin a new era of all-year round living. As the first and only towers in the area, they stand as landmarks of luxurious, self-sufficient homes that do not close their doors after any season, evolving our perception of the North Coast experience.
Being a gateway to the promising New Alamein, The Gate towers consist of 44 floors with a range of studios and four-bedroom apartments, standing on a 5-floor podium filled with amenities and services for the homeowners of the tower, spoiling them like the fanciest high-end hotel.
`
        },
        3: {
            title: 'Top 5 achievers in Badya',
            presenter: 'Palm Hills Developments',
            presenterLogo: images.PalmHills,
            year: '2018',
            description: `This prestigious award recognizes Opia properties as the leading brokerage firm in New October. The award reflects our unwavering commitment to ethical practices, community engagement, and innovative solutions in real estate.
Ideally situated in the heart of New October, Badya is 3000 acres of a prime gateway between the East and the West of Cairo, serving as the focal point for all future extensions. Neighboring the Great Pyramids of Giza, the New Egyptian National Museum, Juhayna Square and the new Sphinx International Airport, Badya is a well-connected city, within a city giving you unprecedented access.
`
        },
        4: {
            title: 'Top 10 achievers in Zed West',
            presenter: 'Ora developers',
            presenterLogo: images.Ora,
            year: '2019',
            description: `Celebrating our forward-thinking approach, this award acknowledges Opia Properties for implementing innovative strategies that enhance the client experience. We are proud to lead the way in utilizing technology to streamline the buying and selling process.

Zed west: tailored exclusively as a 165 feddan real estate compound, surrounding the illustrious ZED Park, ZED el Sheikh Zayed boasts state of the art landscape design, an expansive park and interconnected courtyards. Residents here are within arm’s reach of private landscapes, scenic open spaces and interwoven mixed-use retail and entertainment facilities, all made for exceptional lifestyles.
`
        },
        5: {
            title: 'Top 10 achievers in Owest',
            presenter: 'Orascom development',
            presenterLogo: images.OrascomDev,
            year: '2019',
            description: `This award honors Opia for our dedication to community involvement and social responsibility. We are committed to giving back, and this recognition reflects our team's efforts to support local initiatives and charitable causes.
Owest is set to rise as a beacon of integrated living. Envisioned as a complete sensory experience full of energy, playing differently to everyone, from shops to restaurants, sports club to common spaces, this place offers an experience you can taste ,touch ,smell ,hear ,see and more importantly; feel. It is constantly evolving and changing, adapting and growing. Like an Eco system, everything within O West is intelligently & harmoniously designed to offer a true wholesome town experience.
`
        },
        6: {
            title: 'Top 10 Achievers (Gold Partner) in Mountain View Icity October the Lake& Mountain View Ras El Hikma ',
            presenter: 'DMG (Mountain View)',
            presenterLogo: images.MountainView,
            year: '2020',
            description: `This award reflects our team's commitment to providing exceptional service. Opia Properties has achieved high customer satisfaction ratings, demonstrating our focus on meeting and exceeding client expectations in every transaction.
Every corner of MV Park The Lake is designed to be a mind-altering, freeing experience. MV Park The Lake’s numerous water features and main iconic lake at the heart of the compound are Mountain View’s approach to introduce a new way of enjoying life.
Crystal blue waters and pristine white sands paired with the timeless charm of Greek architecture: The true essence of summer can be found at Mountain View Ras El Hikma. Because we value the importance of family.
`
        },
        7: {
            title: 'Top 10 Achievers (Top Producer) In the Gate Towers',
            presenter: 'City Edge Developments',
            presenterLogo: images.CityEdge,
            year: '2020',
            description: 'Awarded to Opia Properties. Being a gateway to the promising New Alamein, The Gate towers consist of 44 floors with a range of studios and four-bedroom apartments, standing on a 5-floor podium filled with amenities and services for the homeowners of the tower, spoiling them like the fanciest high-end hotel.'
        },
    },
    awardsAr : {
        1: {
            title: 'IL Bosco أفضل 10 إنجازات في',
            presenter: 'شركة مصر ايطاليا العقارية',
            presenterLogo: images.MisrItalia,
            year: '2016',
            description: 'هذه الجائزة تعترف بتحقيقنا لأفضل 10 مبيعات في الإطلاق الأول في IL Bosco في العاصمة الجديدة. نحن فخورون بتلقي هذا التقدير لالتزامنا بهذا الإطلاق لأنهتم التخطيط لمشروع IL BOSCO من قبل شركة CallisonRTKL المشهورة عالميًا بالتعاون مع DMA، ويكشف المشروع عن مفهوم مستوحى من الطبيعة للحياة الصديقة للبيئة لإملاء تجربة معيشية فريدة من نوعها حيث تنتشر الخضرة والرفاهية على مساحة 200 فدان. يقع المشروع بشكل أنيق داخل المنطقة السكنية الراقية R7 في العاصمة الجديدة لتكريم التنمية المستدامة مع الجمع بشكل متناغم بين الوظيفة الحديثة والشكل الجمالي. تم تصميمه مع وضع أنماط الحياة المعاصرة المتنوعة في الاعتبار.'
        },
        2: {
            title: 'أفضل 5 مشاريع في أبراج العلمين الجديدة (أبراج نورث إيدج)',
            presenter: 'City Edge Developments',
            presenterLogo: images.CityEdge,
            year: '2018',
            description: `تم منح هذه الجائزة لشركة Opia properties، لتسليط الضوء على أداء المبيعات المتميز وخدمة العملاء الاستثنائية. ويبرز هذا التكريم تفاني فريقنا في تحقيق التميز وقدرتهم على بناء علاقات دائمة مع العملاء.
تعد أبراج نورث إيدج من الركائز الأساسية لواجهة العلمين الجديدة، وتهدف إلى بدء عصر جديد من الحياة على مدار العام. وباعتبارها الأبراج الأولى والوحيدة في المنطقة، فهي تمثل معالم بارزة للمنازل الفاخرة المكتفية ذاتيًا والتي لا تغلق أبوابها بعد أي موسم، مما يطور تصورنا لتجربة الساحل الشمالي.
`
        },
        3: {
            title: 'أفضل 5 إنجازات في باديا',
            presenter: 'بالم هيلز للتطوير',
            presenterLogo: images.PalmHills,
            year: '2018',
            description: 'تُقدِّم هذه الجائزة المرموقة تقديرًا لشركة أوبيا العقارية باعتبارها شركة الوساطة الرائدة في منطقة أكتوبر الجديدة. باديا، أحدث وأعظم مشروع محتمل لشركة بالم هيلز للتطوير العقاري، يعد بعالم من الأولويات - سواء في الحلول الذكية والتكنولوجية المنزلية، أو راحة نمط الحياة، أو الإبداع المستنير، أو الثقافة العميقة، أو التعهد المخلص بالحفاظ على الهواء الطلق والداخلي. مشروعنا الأحدث هو مشروع تقاسم الإيرادات / التنمية المشتركة مع هيئة المجتمعات العمرانية الجديدة، ويمتد على مساحة 3000 فدان، ويتكون من 6 مناطق مستقلة متكاملة تمامًا في قلب مدينة السادس من أكتوبر. باديا هي المرساة الجديدة للغرب، التي تربط مدينة السادس من أكتوبر.'
        },
        4: {
            title: 'أفضل 10 إنجازات في زيد ويست (الشيخ زايد)',
            presenter: 'مطوري أورا',
            presenterLogo: images.Ora,
            year: '2019',
            description: `احتفالاً بنهجنا التقدمي، تأتي هذه الجائزة تقديراً لشركة Opia Properties لتطبيقها استراتيجيات مبتكرة تعمل على تعزيز تجربة العميل.
زيد الشيخ زايد 165 فدان من العقارات الفاخرة المحيطة
حديقة ZED الفريدة من نوعها، والتي تتميز بمناظر طبيعية رائعة وحديثة
التصميم، يمتد المنتزه الواسع على كامل التطوير في السلسلة
من الساحات المترابطة.
`
        },
        5: {
            title: 'O-West أفضل 10 أشخاص حققوا إنجازات في',
            presenter: 'أوراسكوم للتنمية',
            presenterLogo: images.OrascomDev,
            year: '2019',
            description: 'تكرّم هذه الجائزة شركة Opia Properties لتفانيها في المشاركة المجتمعية والمسؤولية الاجتماعية.من المقرر أن يصبح O West منارة للحياة المتكاملة. تم تصوره كتجربة حسية كاملة مليئة بالطاقة، تلعب بشكل مختلف للجميع، من المتاجر إلى المطاعم، والنوادي الرياضية إلى المساحات المشتركة، يقدم هذا المكان تجربة يمكنك تذوقها ولمسها وشمها وسماعها ورؤيتها والأهم من ذلك؛ الشعور بها. إنه يتطور ويتغير باستمرار ويتكيف وينمو. مثل النظام البيئي، تم تصميم كل شيء داخل O West بذكاء وتناغم لتقديم تجربة مدينة صحية حقيقية.'
        },
        6: {
            title: 'أفضل 10 شركات (شريك ذهبي) في ماونتن فيو آي سيتي أكتوبر البحيرة وماونتن فيو رأس الحكمة',
            presenter: 'DMG (ماونتن فيو)',
            presenterLogo: images.MountainView,
            year: '2020',
            description: `تعكس هذه الجائزة التزام فريقنا بتقديم خدمة استثنائية. وقد حققت شركة Opia Properties معدلات عالية من رضا العملاء، مما يدل على تركيزنا على تلبية وتجاوز توقعات العملاء في كل معاملة.
تم تصميم كل ركن من أركان MV Park The Lake ليكون تجربة تحررية تغير العقل. تعد ميزات المياه العديدة في MV Park The Lake والبحيرة الرئيسية الشهيرة في قلب المجمع هي نهج Mountain View لتقديم طريقة جديدة للاستمتاع بالحياة
المياه الزرقاء الصافية والرمال البيضاء النقية جنبًا إلى جنب مع سحر العمارة اليونانية الخالدة: يمكن العثور على جوهر الصيف الحقيقي في ماونتن فيو راس الحكمة. ولأننا نقدر أهمية الأسرة
`
        },
        7: {
            title: 'أفضل 10 محققين (أفضل منتج) في أبراج البوابة',
            presenter: 'City Edge Developments',
            presenterLogo: images.CityEdge,
            year: '2020',
            description: `مُنحت إلى شركة Opia Properties.باعتبارها بوابة إلى مدينة العلمين الجديدة الواعدة، تتكون أبراج ذا جيت من 44 طابقًا مع مجموعة من الاستوديوهات والشقق المكونة من أربع غرف نوم، والتي تقف على منصة من 5 طوابق مليئة بوسائل الراحة والخدمات لأصحاب المنازل في البرج، لتدليلهم مثل أفخم الفنادق الراقية.`
        },
    }
}