import React, { useContext } from 'react'
import DeveloperComponent from './DeveloperComponent'
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import MobileSearchComponent from '../../../components/MobileSearchComponent';

const DevelopersSection = () => {

    const navigate = useNavigate();

    const { developers , isSelectedLanguageEnglish } = useContext(AppContext);

    return (
        <div className='flex flex-col p-4 m-4 border shadow-md rounded-lg'>
            <div className='md:hidden py-2 w-full flex items-center justify-center'>
                <MobileSearchComponent />
            </div>
            <div className='flex items-center p-2'>
                <span className={`text-4xl ${!isSelectedLanguageEnglish && 'text-right' } w-full font-bold text-nubaqgreen`}>{isSelectedLanguageEnglish ? 'Developers' : 'المطورين'}</span>
            </div>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-3 m-4'>
                {
                    developers.slice(0,3).map((developer) => {
                        return(
                        <DeveloperComponent developer={developer} />
                        )
                    })
                }
                <button onClick={()=>{window.scrollTo(0,0); navigate('/developers');}} className='w-full h-full flex items-center justify-center border-2 rounded-lg text-2xl bg-gray-100 font-semibold transition-all duration-500 hover:scale-105'>
                    {isSelectedLanguageEnglish ? 'See More' : 'اكتشف المزيد'}
                </button>
            </div>
        </div>
    )
}

export default DevelopersSection
