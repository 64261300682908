import React, { useContext } from 'react'
import ProjectComponent from './ProjectComponent';
import { AppContext } from '../../../AppContext';
import { useNavigate } from 'react-router-dom';

const ProjectsSection = () => {
  
    const navigate = useNavigate();
    const { projects , isSelectedLanguageEnglish } = useContext(AppContext);

    return (
        <div className='flex flex-col p-4 m-4 border shadow-md rounded-lg'>
            <div className='flex items-center p-2'>
                <span className={`text-4xl w-full ${!isSelectedLanguageEnglish && 'text-right'} font-bold text-nubaqgreen`}>{isSelectedLanguageEnglish ? 'Projects' : 'المشاريع'}</span>
            </div>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-3 m-4'>
                {
                    projects.slice(0,3).map((project) => {
                        return(
                        <ProjectComponent project={project} />
                        )
                    })
                }
                <button onClick={()=>{window.scrollTo(0,0); navigate('/projects');}} className='w-full h-full flex items-center justify-center border-2 rounded-lg text-2xl bg-gray-100 font-semibold transition-all duration-500 hover:scale-105'>
                    {isSelectedLanguageEnglish ? 'See More' : 'اكتشف المزيد'}
                </button>
            </div>
        </div>
    )
}

export default ProjectsSection
