import React, { useEffect, useState, useRef, useContext } from 'react';
import { Awards as awardsData } from '../../../data/Awards';
import { AppContext } from '../../../AppContext';
import AwardComponent from '../../../components/AwardComponent';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Awards = () => {

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const { isSelectedLanguageEnglish } = useContext(AppContext);
  const awards = isSelectedLanguageEnglish ? awardsData.awardsEn : awardsData.awardsAr;

  useEffect(() => {
    const currentSection = sectionRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(currentSection); // Stop observing once it's visible
        }
      },
      { threshold: 0.2 }
    );

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) observer.unobserve(currentSection); // Cleanup
    };
  }, []);

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true, // Added pause on hover for better user experience
  };

  return (
    <section
      ref={sectionRef}
      id='awards'
      className={`flex flex-col items-center justify-center w-full p-2 md:p-8 transition-opacity duration-1000 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      <div className='text-4xl font-bold text-nubaqgreen'>
        {isSelectedLanguageEnglish ? 'Awards' : 'انجازات'}
      </div>
      <div className='w-full h-full mt-4'>
        <Slider {...settings}>
          {/* 2016 Awards */}
          <div className='w-full m-2 p-4 rounded-xl flex flex-col gap-6'>
            <div
              className={`p-2 ${isSelectedLanguageEnglish ? 'border-l-4 text-left' : 'border-r-4 text-right'} border-nubaqgreen font-semibold text-2xl`}
            >
              2016
            </div>
            <div className='flex flex-col md:flex-row gap-4 w-full px-4'>
              <AwardComponent award={awards[1]} />
            </div>
          </div>
          {/* 2018 Awards */}
          <div className='w-full m-2 p-4 rounded-xl flex flex-col gap-6'>
            <div
              className={`p-2 ${isSelectedLanguageEnglish ? 'border-l-4 text-left' : 'border-r-4 text-right'} border-nubaqgreen font-semibold text-2xl`}
            >
              2018
            </div>
            <div className='flex flex-col md:flex-row gap-4 w-full px-4'>
              <AwardComponent award={awards[2]} />
              <AwardComponent award={awards[3]} />
            </div>
          </div>
          {/* 2019 Awards */}
          <div className='w-full m-2 p-4 rounded-xl flex flex-col gap-6'>
            <div
              className={`p-2 ${isSelectedLanguageEnglish ? 'border-l-4 text-left' : 'border-r-4 text-right'} border-nubaqgreen font-semibold text-2xl`}
            >
              2019
            </div>
            <div className='flex flex-col md:flex-row gap-4 w-full px-4'>
              <AwardComponent award={awards[4]} />
              <AwardComponent award={awards[5]} />
            </div>
          </div>
          {/* 2020 Awards */}
          <div className='w-full m-2 p-4 rounded-xl flex flex-col gap-6'>
            <div
              className={`p-2 ${isSelectedLanguageEnglish ? 'border-l-4 text-left' : 'border-r-4 text-right'} border-nubaqgreen font-semibold text-2xl`}
            >
              2020
            </div>
            <div className='flex flex-col md:flex-row gap-4 w-full px-4'>
              <AwardComponent award={awards[6]} />
              <AwardComponent award={awards[7]} />
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Awards;
