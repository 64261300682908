import React, { useContext } from 'react'
import { BiArea, BiBed, BiDollar } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../AppContext';


const UnitComponent = ({unit}) => {

    const navigate = useNavigate();
    const {isSelectedLanguageEnglish} = useContext(AppContext);

    return (
        <button onClick={()=>{window.scrollTo(0,0); navigate(`/units/${unit._id}`, { state: { unit } }); }} className='relative rounded-lg flex flex-col border-2 transition-all duration-500 hover:scale-105'>
            <img src={unit.images[0]} alt='logo' className='rounded-lg h-2/3 w-full' />
            <div className='w-full bg-gray-100 font-semibold text-gray-600 text-sm p-2 flex flex-col items-center justify-center'>
                <span className={`text-gray-800 w-full py-2 items-start justify-start text-start text-md ${!isSelectedLanguageEnglish && 'text-end'}`}>{isSelectedLanguageEnglish ? unit.title : unit.arabicTitle}</span>
                <div className={`truncate w-full`}>{isSelectedLanguageEnglish ? unit.description : unit.arabicDescription}</div>
                <div className='flex justify-between w-full'>
                    <span className='flex gap-1 items-center justify-center'><BiBed className='text-lg text-green-500' />{unit.bedrooms}</span>
                    <span className='flex gap-1 items-center justify-center'><BiArea className='text-lg text-green-500' />{unit.area}m</span>
                </div>
                <div className='text-green-500 flex items-center justify-center'><BiDollar className='text-gray-600'/>{unit.price}</div>
                <div className={`flex ${!isSelectedLanguageEnglish && 'flex-row-reverse'} justify-between w-full`}>
                    <div>{isSelectedLanguageEnglish ? unit.project : unit.arabicProject}</div>
                    <div>{isSelectedLanguageEnglish ? unit.developer : unit.arabicDeveloper}</div>
                </div>
            </div>
        </button>
    )
}

export default UnitComponent
