import React, { useContext } from 'react'
import { AppContext } from '../../../AppContext'
import DeveloperComponent from '../../DiscoverPage/containers/DeveloperComponent';

const AllDevelopers = () => {

    const { developers , isSelectedLanguageEnglish} = useContext(AppContext);

    return (
        <div className='p-4 w-full flex flex-col gap-6 border shadow-xl rounded-xl bg-white'>
            <div className='font-bold text-3xl text-nubaqgreen text-center'>{isSelectedLanguageEnglish ? 'Developers' : 'المطورين'}</div>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2'>
                {developers.map((developer) => (
                    <DeveloperComponent developer={developer} />
                ))}
            </div>
        </div>
    )
}

export default AllDevelopers
