import React, { useContext } from 'react'
import images from '../../../constants/Images'
import SearchComponent from '../../../components/SearchComponent'
import { AppContext } from '../../../AppContext'
const DiscoverHero = () => {

  const {isSelectedLanguageEnglish} = useContext(AppContext);

  return (
    <div id='hero' className='relative w-full mt-0'>
      <img src={images.image14} alt='Discover Hero' className='relative w-full max-h-[600px]' />
      <div className='absolute top-0 z-10 bg-black opacity-60 w-full h-full flex items-center justify-center'></div>
      <div className='absolute top-0 z-10 w-full h-full gap-8 flex flex-col items-center justify-center'>
        <span className='w-2/3 pt-12 md:pt-8 lg:pt-0 items-center justify-center text-center text-white font-semibold text-[30px] md:text-[50px]'>{isSelectedLanguageEnglish ? 'Discover your desired unit' : 'اكتشف وحدتك المفضلة'}</span>
        <SearchComponent />
      </div>
    </div>
  )
}

export default DiscoverHero
