import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {AppContext} from '../../../AppContext';

const DeveloperComponent = ({developer}) => {

  const {isSelectedLanguageEnglish} = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <button onClick={() => {window.scrollTo(0,0); navigate(`/developer/${developer._id}`);}} className='relative rounded-lg flex flex-col border-2 transition-all duration-500 hover:scale-105'>
      <img src={developer.logoLink} alt='logo' className='rounded-lg h-2/3 w-full' />
      <div className='w-full bg-gray-100 text-black font-semibold h-1/3 text-lg py-2 flex items-center justify-center'>
        {isSelectedLanguageEnglish ? developer.name : developer.arabicName}
      </div>
    </button>
  )
}

export default DeveloperComponent
