import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import DiscoverPage from "./pages/DiscoverPage/DiscoverPage";
import DevelopersPage from "./pages/DevelopersPage/DevelopersPage";
import DeveloperPage from "./pages/DeveloperPage/DeveloperPage";
import UnitsPage from "./pages/UnitsPage/UnitsPage";
import UnitPage from "./pages/UnitPage/UnitPage";
import FinishPage from "./pages/FinishPage/FinishPage";
import ListForRentPage from "./pages/ListForRentPage/ListForRentPage";
import SellPage from "./pages/SellPage/SellPage";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage";
import ProjectPage from "./pages/ProjectPage/ProjectPage";

function App() {
  return (
    <div className="bg-[#fafafa] font-heebo">
      <Routes>
        <Route index path="/" element={<HomePage />}></Route>
        <Route path="/discover" element={<DiscoverPage />}/>
        <Route path="/developers" element={<DevelopersPage />}/>
        <Route path="/developer/:id" element={<DeveloperPage />} />
        <Route path="/projects" element={<ProjectsPage />}/>
        <Route path="/project/:id" element={<ProjectPage />} />
        <Route path="/units" element={<UnitsPage forRent={false} />}/>
        <Route path="/units/rent" element={<UnitsPage forRent={true} />}/>
        <Route path="/units/:id" element={<UnitPage />}/>
        <Route path="/finish" element={<FinishPage />}/>
        <Route path="/sell" element={<SellPage />}/>
        <Route path="/listForRent" element={<ListForRentPage />}/>
      </Routes>
    </div>
  );
}

export default App;
