import React, { useContext } from 'react'
import Slider from 'react-slick';
import { TopDevs } from '../../../constants/Devs';
import { AppContext } from '../../../AppContext';

const TopDevelopers = () => {

    const { isSelectedLanguageEnglish } = useContext(AppContext);

    // Responsive Slider settings
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 2000,
        cssEase: "linear",
        pauseOnHover: false,
        slidesToShow: 5, // Default number of slides on large screens
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // For tablets
                settings: {
                    slidesToShow: 4, // Show 3 items on medium screens
                },
            },
            {
                breakpoint: 768, // For mobile devices
                settings: {
                    slidesToShow: 3, // Show 2 items on smaller screens
                },
            },
            {
                breakpoint: 480, // For very small screens (portrait mobile)
                settings: {
                    slidesToShow: 2, // Show 1 item on very small screens
                },
            },
        ],
    };

    return (
        <div className='w-full py-6 bg-gray-200'>
            <div className="text-center mb-10 mt-4">
                <h2 className="text-4xl font-bold text-nubaqgreen">{isSelectedLanguageEnglish ? 'Top developers in Egypt' : 'اكبر مطورين فى مصر'}</h2>
            </div>
            <Slider {...settings}>
                {
                    TopDevs.map((dev, index) => (
                        <div key={index} className='flex flex-col items-center justify-center px-2'>
                            <div className='flex items-center justify-center w-full'>
                                <img
                                    className='w-32 h-32 rounded-full border-2 border-nubaqgreen'
                                    src={dev.logo}
                                    alt='logo'
                                    style={{ objectFit: 'cover' }}
                                />
                            </div>
                            <div className='text-center font-semibold pt-2'>{dev.name}</div>
                        </div>
                    ))
                }
            </Slider>
        </div>
    );
};

export default TopDevelopers;
