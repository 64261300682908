import React, { useContext } from 'react'
import UnitComponent from '../../../components/UnitComponent'
import { MdOutlineCancel } from 'react-icons/md'
import { AppContext } from '../../../AppContext'

const FilteredUnitsSection = ({ units }) => {

    const {isSelectedLanguageEnglish} = useContext(AppContext);

    return (
        <div className='flex flex-col p-4 m-4 border shadow-md rounded-lg'>
            <div className='flex justify-between items-center p-2'>
                <span className={`text-2xl w-full mx-8 font-semibold underline underline-offset-8 ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'Filtered Units' : 'الوحدات المصنفة'}</span>
            </div>
            { units.length === 0 ?
                <div className='w-full h-full p-16 flex flex-col gap-2 text-gray-600 items-center justify-center'>
                    <MdOutlineCancel className='text-[48px]' />
                    <label>{isSelectedLanguageEnglish ? 'No units matches filters' : 'لا يوجد وحدات مطابقة لهذة التصنيفات'}</label>
                </div>
                :
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 m-4'>
                    {
                        units.map((unit) => {
                            return (
                                <UnitComponent unit={unit} />
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default FilteredUnitsSection
