import React, { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { Services } from '../../../data/Services';

const Discover = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const { isSelectedLanguageEnglish } = useContext(AppContext);
  const services = isSelectedLanguageEnglish ? Services.servicesEn : Services.servicesAr;

  useEffect(() => {
    const currentSection = sectionRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(currentSection); // Stop observing once it's visible
        }
      },
      { threshold: 0.2 } // Trigger when 20% of the section is visible
    );

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) observer.unobserve(currentSection);
    };
  }, []);

  return (
    <section
      id="discover"
      ref={sectionRef}
      className={`flex flex-col gap-8 justify-center w-full transition-opacity duration-1000 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      <div className="flex flex-col w-full items-center bg-nubaqgreen py-12">
        <div className="text-4xl font-bold text-[#100c08] p-8">
          {isSelectedLanguageEnglish ? 'Services' : 'خدماتنا'}
        </div>

        {/* Grid Layout with specific centering on tablet screens for the 5th button */}
        <div
          className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 px-4 ${!isSelectedLanguageEnglish && 'flex-row-reverse'
            }`}
        >
          {/* First 4 Buttons */}
          <button
            onClick={() => {
              window.scrollTo(0, 0); // Scroll to the top
              navigate('/discover');
            }}
            className="group relative bg-neutral-100 border-2 border-[#100c08] transition-all duration-500 rounded-lg shadow-2xl hover:text-black m-2 group h-[200px] md:h-[250px] w-auto"
          >
            <div className="h-2/3 px-2">{services.buy.desc}</div>
            <div className="absolute group-hover:bg-gray-200 group-hover:text-black group-hover:rounded-t-none group-hover:border-t border-[#100c08] group-hover:h-1/3 transition-all duration-500 bottom-0 w-full h-full flex items-center justify-center text-center bg-white z-10 text-[30px] text-black rounded-lg">
              {services.buy.text}
            </div>
          </button>

          <button
            onClick={() => {
              window.scrollTo(0, 0); // Scroll to the top
              navigate('/units/rent');
            }}
            className="group relative bg-neutral-100 border-2 border-[#100c08] transition-all duration-500 rounded-lg shadow-2xl hover:text-black m-2 group h-[200px] md:h-[250px] w-auto"
          >
            <div className="h-2/3 px-2">{services.rentNew.desc}</div>
            <div className="absolute group-hover:bg-gray-200 group-hover:text-black group-hover:rounded-t-none group-hover:border-t border-[#100c08] group-hover:h-1/3 transition-all duration-500 bottom-0 w-full h-full flex items-center justify-center text-center bg-white z-10 text-[26px] text-black rounded-lg">
              {services.rentNew.text}
            </div>
          </button>

          <button
            onClick={() => {
              window.scrollTo(0, 0); // Scroll to the top
              navigate('/sell');
            }}
            className="group relative bg-neutral-100 border-2 border-[#100c08] transition-all duration-500 rounded-lg shadow-2xl hover:text-black m-2 group h-[200px] md:h-[250px] w-auto"
          >
            <div className="h-2/3 px-2">{services.sell.desc}</div>
            <div className="absolute group-hover:bg-gray-200 group-hover:text-black group-hover:rounded-t-none group-hover:border-t border-[#100c08] group-hover:h-1/3 transition-all duration-500 bottom-0 w-full h-full flex items-center justify-center text-center bg-white z-10 text-[30px] text-black rounded-lg">
              <span>{services.sell.text}</span>
            </div>
          </button>

          <button
            onClick={() => {
              window.scrollTo(0, 0); // Scroll to the top
              navigate('/listForRent');
            }}
            className="group relative bg-neutral-100 border-2 border-[#100c08] transition-all duration-500 rounded-lg shadow-2xl hover:text-black m-2 group h-[200px] md:h-[250px] w-auto"
          >
            <div className="h-2/3 px-2">{services.rentOwn.desc}</div>
            <div className="absolute group-hover:bg-gray-200 group-hover:text-black group-hover:rounded-t-none group-hover:border-t border-[#100c08] group-hover:h-1/3 transition-all duration-500 bottom-0 w-full h-full flex items-center justify-center text-center bg-white z-10 text-[26px] text-black rounded-lg">
              <span>{services.rentOwn.text}</span>
            </div>
          </button>

          <button
            onClick={() => {
              window.scrollTo(0, 0); // Scroll to the top
              navigate('/finish');
            }}
            className="group relative bg-neutral-100 border-2 border-[#100c08] transition-all duration-500 rounded-lg shadow-2xl hover:text-black m-2 group h-[200px] md:h-[250px] w-auto sm:col-span-2 lg:col-span-1"
          >
            <div className="h-2/3 px-2">{services.finishing.desc}</div>
            <div className="absolute group-hover:bg-gray-200 group-hover:text-black group-hover:rounded-t-none group-hover:border-t border-[#100c08] group-hover:h-1/3 transition-all duration-500 bottom-0 w-full h-full flex items-center justify-center text-center bg-white z-10 text-[30px] text-black rounded-lg">
              <span>{services.finishing.text}</span>
            </div>
          </button>

        </div>
      </div>
    </section>
  );
};

export default Discover;
