import React, { useContext } from 'react';
import images from '../../../constants/Images';
import ImageOutlet from '../../../components/ImageOutlet';
import { AppContext } from '../../../AppContext';
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router-dom';

const Video = () => {
  const { isSelectedLanguageEnglish } = useContext(AppContext);
  const navigate = useNavigate();
  
  const seq = isSelectedLanguageEnglish ? [
    'Cash your life back...',
    'We invest in people...',
    'The future of Real Estate...',
    'We strive to bring privileges...',
    'Lifestyle is ESSENTIAL...'
  ] : [
    '.استرد حياتك نقدًا',
    '.نحن نستثمر في الناس',
    '.مستقبل العقارات',
    '.نحن نسعى جاهدين لتحقيق الامتيازات',
    '.نمط الحياة ضروري'
  ];

  const imgs = [
    images.image10,
    images.image12,
    images.image7,
    images.image8,
    images.image9,
  ];

  return (
    <div id='video' className="flex relative w-full h-[350px] md:h-[550px] lg:h-screen justify-center items-center bg-gray-100">
      <ImageOutlet images={imgs} interval={5000} />
      <div className='absolute text-white text-xs md:text-2xl lg:text-3xl font-bold w-full h-full flex flex-col gap-y-8 items-center justify-center bg-black bg-opacity-70'>
        <div className='w-full h-1/3'></div>
        <TypeAnimation
          key={isSelectedLanguageEnglish ? 'english' : 'arabic'} // Unique key for each language
          sequence={seq}
          wrapper='span'
          speed={0.002}
          style={{ fontSize: '2em', display: 'inline-block' }}
          repeat={Infinity}
        />
        <div className='grid grid-cols-2 md:flex items-center text-sm md:text-lg lg:text-xl justify-center w-5/6 md:w-2/3 h-fit lg:h-1/3 pt-6 md:pt-16 pb-4 md:pb-0'>
          <button
            onClick={() => {
              window.scrollTo(0, 0); // Scroll to the top
              navigate('/discover');
            }}
            className='w-full md:w-1/4 transition-all duration-500 hover:bg-nubaqgreen hover:text-white h-full lg:h-1/2 py-3 px-1 border-b-2 md:border-b-0 border-r-2 border-gray-400 rounded-tl-xl md:rounded-l-xl bg-white bg-opacity-15 text-white'>{isSelectedLanguageEnglish ? 'Buy' : 'أشترى'}</button>
          <button
            onClick={() => {
              window.scrollTo(0, 0); // Scroll to the top
              navigate('/units/rent');
            }}
            className='w-full md:w-1/4 transition-all duration-500 hover:bg-nubaqgreen hover:text-white h-full lg:h-1/2 py-3 px-1 border-b-2 md:border-b-0 md:border-r-2 border-gray-400 rounded-tr-xl md:rounded-none bg-white bg-opacity-15 text-white'>{isSelectedLanguageEnglish ? 'Rent' : 'أستأجر'}</button>
          <button
            onClick={() => {
              window.scrollTo(0, 0); // Scroll to the top
              navigate('/sell');
            }}
            className='w-full md:w-1/4 transition-all duration-500 hover:bg-nubaqgreen hover:text-white h-full lg:h-1/2 py-3 px-1 border-r-2 border-gray-400 rounded-bl-xl md:rounded-none bg-white bg-opacity-15 text-white'>{isSelectedLanguageEnglish ? 'Sell' : 'بيع'}</button>
          <button
            onClick={() => {
              window.scrollTo(0, 0); // Scroll to the top
              navigate('/finish');
            }}
            className='w-full md:w-1/4 transition-all duration-500 hover:bg-nubaqgreen hover:text-white h-full lg:h-1/2 py-3 px-1 md:rounded-r-xl bg-white rounded-br-xl bg-opacity-15 text-white break-words'>{isSelectedLanguageEnglish ? 'Design and Finishing' : 'تصميم و تشطيب'}</button>
        </div>
      </div>
    </div>
  );
};

export default Video;
