
import React from 'react'
import { AiOutlineYoutube } from 'react-icons/ai'
import { FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa'
import { RiTwitterXLine } from 'react-icons/ri'
import { SlSocialFacebook, SlSocialLinkedin } from 'react-icons/sl'

const SocialMedia = () => {
  return (
    <div className='flex justify-between w-fit gap-x-2 md:gap-x-4'>
      {/* Whatsapp */}
      <a
        href='https://wa.me/message/26AJURO347ESI1'
        target='_blank'
        rel='noopener noreferrer'
        className='w-10 h-10 md:w-14 md:h-14 group flex items-center justify-center  rounded-full transition-all duration-500 hover:bg-green-400'
      >
        <FaWhatsapp className='transition-all duration-500 text-white text-md md:text-2xl' />
      </a>
      {/* Facebook */}
      <a
        href='https://www.facebook.com/NUBAQEG?mibextid=JRoKGi'
        target='_blank'
        rel='noopener noreferrer'
        className='w-10 h-10 md:w-14 md:h-14 group flex items-center justify-center  rounded-full transition-all duration-500 hover:bg-[#3b5998]'
      >
        <SlSocialFacebook className='transition-all duration-500 text-white text-md md:text-2xl' />
      </a>

      {/* Twitter (X) */}
      <a
        href='https://x.com/NUBAQEG'
        target='_blank'
        rel='noopener noreferrer'
        className='w-10 h-10 md:w-14 md:h-14 flex items-center justify-center  rounded-full transition-all duration-500 hover:bg-[#1DA1F2]'
      >
        <RiTwitterXLine className='text-white text-md md:text-xl' />
      </a>

      {/* LinkedIn */}
      <a
        href='https://www.linkedin.com/company/nubaq/'
        target='_blank'
        rel='noopener noreferrer'
        className='w-10 h-10 md:w-14 md:h-14 group flex items-center justify-center  rounded-full transition-all duration-500 hover:bg-[#0077B5]'
      >
        <SlSocialLinkedin className='transition-all duration-500 text-white text-md md:text-2xl' />
      </a>

      {/* Instagram */}
      <a
        href='https://www.instagram.com/nubaq.eg?igsh=MWNpb2dwZTc3Y3BsNA=='
        target='_blank'
        rel='noopener noreferrer'
        className='group w-10 h-10 md:w-14 md:h-14 flex items-center justify-center  rounded-full transition-all duration-500'
      >
        <div className='w-10 h-10 md:w-14 md:h-14 flex items-center justify-center rounded-full group-hover:bg-gradient-to-r group-hover:from-[#F58529] group-hover:via-[#DD2A7B] group-hover:to-[#8134AF] transition-all duration-500'>
          <FaInstagram className='transition-all duration-500 text-white text-md md:text-2xl' />
        </div>
      </a>

      {/* YouTube */}
      <a
        href='https://youtube.com/@nubaq?si=GzrZlHFoYhGrnKGl'
        target='_blank'
        rel='noopener noreferrer'
        className='w-10 h-10 md:w-14 md:h-14 group flex items-center justify-center  rounded-full transition-all duration-500 hover:bg-[#FF0000]'
      >
        <AiOutlineYoutube className='transition-all duration-500 text-white text-md md:text-2xl' />
      </a>

      {/* TikTok */}
      <a
        href='https://www.tiktok.com/@nubaqeg?_t=8rb3QC38TwY&_r=1'
        target='_blank'
        rel='noopener noreferrer'
        className='w-10 h-10 md:w-14 md:h-14 flex items-center justify-center  rounded-full transition-all duration-500 hover:bg-[#000000] group'
      >
        <FaTiktok className='text-white text-md md:text-xl transition-all duration-500' />
      </a>

    </div>
  )
}

export default SocialMedia
