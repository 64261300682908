import images from "../constants/Images";

export const Partners = {
    partnersEn: {
        title: `Nubaq Properties proudly collaborates with some of the world’s most prestigious property developers, including Damac Properties, Emaar Dubai, Sobha Realty, and Buildyard. These partnerships allow us to showcase a range of luxury residential and commercial properties, from high-rise towers to exclusive villas. Our alliances with these industry leaders provide our clients exclusive access to high-quality developments, reflecting the pinnacle of modern living.`,
        damac: {
            name: 'Damac Properties',
            logo: images.Damac,
            description: 'Damac is a prominent player in the UAE’s luxury real estate market, renowned for sophisticated residences and record-breaking developments. Key facts include:',
            points: [
                'Developer of the Year Award by Finance World Magazine in 2024',
                'Established in 2002 by visionary entrepreneur Mr. Hussain Sajwani',
                'A global footprint across 10 countries, offering properties such as apartments, villas, townhouses, plots, and hotels',
                'Collaborations with 50+ international design houses and 200+ strategic partners ensure elite, innovative designs',
                'Over 2,600 employees dedicated to creating premium, lifestyle-enhancing communities',
            ]
        },
        emaar: {
            name: 'Emaar Dubai',
            logo: images.Emaar,
            description: 'Emaar Properties is globally recognized for its landmark developments, including the Burj Khalifa, Dubai Mall, and numerous high-end communities across the Middle East. Emaar’s expertise ensures luxurious living spaces that combine quality with innovation. Key highlights:',
            points: [
                'Founded in 1997 by Mohamed Alabbar',
                'Develops iconic projects globally, with major footprints in the UAE, Saudi Arabia, Egypt, and Turkey',
                'Renowned for master-planned communities such as Downtown Dubai and Arabian Ranches, redefining luxury urban living',
                'Listed on the Dubai Financial Market and included in the MSCI Emerging Markets Index',
                'Known for integrated developments that balance residential, commercial, and leisure spaces, providing a lifestyle of convenience and sophistication'
            ]
        },
        sobha: {
            name: 'Sobha Realty',
            logo: images.Sobha,
            description: 'With origins in India, Sobha Realty has built a reputation for precision and quality. Now headquartered in Dubai, Sobha develops high-end properties that emphasize meticulous craftsmanship and a commitment to sustainability. Notable details include:',
            points: [
                'Established in 1976 by P.N.C. Menon',
                'Known for in-house design and engineering teams, ensuring attention to every project detail',
                'A portfolio that includes luxurious villas, apartments, and extensive green landscapes in Sobha Hartland and other premier locations in Dubai',
                'Focus on sustainable practices, integrating green spaces and advanced construction methods',
                `Awards for quality and innovation, with a commitment to delivering properties that enhance residents' well-being and the environment`
            ]
        },
        buildyard: {
            name: 'Buildyard',
            logo: images.Buildyard,
            description: 'Buildyard, established in Cairo, specializes in comprehensive interior and exterior design for both residential and commercial properties. Known for high standards in construction management, Buildyard is committed to delivering aesthetically pleasing, structurally sound spaces. Key aspects:',
            points: [
                'Founded in 2014, with a focus on craftsmanship and attention to detail',
                'Provides full-service construction management, cost estimation, and project planning, ensuring a seamless building experience',
                'Known for its work in prominent compounds like Allegria, SODIC, and New Giza',
                'Offers customized solutions from structural engineering to swimming pools, tiling, plumbing, and electrical work',
                'A trusted partner for creating spaces that enhance quality of life, bringing clients’ visions to reality through expertise and professionalism'
            ]
        }
    },
    partnersAr: {
        title: `تفتخر شركة نوباك العقارية بالتعاون مع بعض من أرقى مطوري العقارات في العالم، بما في ذلكداماك العقارية,إعمار دبي,صبحا العقارية، وساحة البناءتتيح لنا هذه الشراكات عرض مجموعة من العقارات السكنية والتجارية الفاخرة، من الأبراج الشاهقة إلى الفيلات الحصرية. توفر تحالفاتنا مع رواد الصناعة هؤلاء لعملائنا إمكانية الوصول الحصري إلى مشاريع عالية الجودة تعكس قمة الحياة العصرية`,
        damac: {
            name: 'داماك العقارية',
            logo: images.Damac,
            description: 'تعد داماك لاعباً بارزاً في سوق العقارات الفاخرة في الإمارات العربية المتحدة، وتشتهر بمنازلها المتطورة ومشاريعها التي حطمت الأرقام القياسية. وتتضمن الحقائق الرئيسية ما يلي',
            points: [
                'في عام 2024'+' Finance World '+'جائزة مطور العام من قبل مجلة',
                'تأسست في عام 2002 على يد رجل الأعمال صاحب الرؤية السيد حسين سجواني',
                'بصمة عالمية في 10 دول، تقدم عقارات مثل الشقق والفيلات والمنازل والقطع الأرضية والفنادق',
                'التعاون مع أكثر من 50 دار تصميم دولية وأكثر من 200 شريك استراتيجي يضمن تصميمات مبتكرة ونخبوية',
                'أكثر من 2600 موظف مخصصون لإنشاء مجتمعات متميزة تعزز نمط الحياة',
            ]
        },
        emaar: {
            name: 'إعمار دبي',
            logo: images.Emaar,
            description: 'إعمار العقارية تشتهر شركة إعمار العقارية عالميًا بمشاريعها التطويرية البارزة، بما في ذلك برج خليفة ودبي مول والعديد من المجتمعات الراقية في مختلف أنحاء الشرق الأوسط. وتضمن خبرة إعمار توفير مساحات معيشية فاخرة تجمع بين الجودة والابتكار. ومن أبرز النقاط',
            points: [
                'تأسست في عام 1997 على يد محمد العبار',
                'تطوير مشاريع مميزة على مستوى العالم، مع وجود بصمات كبيرة في الإمارات العربية المتحدة والمملكة العربية السعودية ومصر وتركيا',
                'تشتهر بمجتمعاتها المخططة الرئيسية مثل وسط مدينة دبي والمرابع العربية، والتي تعيد تعريف الحياة الحضرية الفاخرة',
                'للأسواق الناشئة'+' MSCI '+'مدرجة في سوق دبي المالي ومدرجة في مؤشر',
                'تشتهر بالتطورات المتكاملة التي توازن بين المساحات السكنية والتجارية والترفيهية، وتوفر أسلوب حياة مريح ومتطور'
            ]
        },
        sobha: {
            name: 'صبحا العقارية',
            logo: images.Sobha,
            description: 'مع أصول في الهند، صبحا العقارية لقد اكتسبت شركة سوبها سمعة طيبة بفضل دقتها وجودتها. والآن يقع مقرها الرئيسي في دبي، وتقوم بتطوير عقارات راقية تؤكد على الحرفية الدقيقة والالتزام بالاستدامة. وتتضمن التفاصيل البارزة ما يلي',
            points: [
                ' PNC Menon '+'تأسست في عام 1976 بواسطة',
                'تشتهر بفرق التصميم والهندسة الداخلية، مما يضمن الاهتمام بكل تفاصيل المشروع',
                'محفظة تضم فللًا فاخرة وشققًا ومناظر طبيعية خضراء واسعة في سوبها هارتلاند ومواقع مميزة أخرى في دبي',
                'التركيز على الممارسات المستدامة ودمج المساحات الخضراء وطرق البناء المتقدمة',
                'جوائز الجودة والابتكار، مع الالتزام بتقديم عقارات تعزز رفاهية السكان والبيئة'
            ]
        },
        buildyard: {
            name: 'بيلد يارد',
            logo: images.Buildyard,
            description: 'تأسست شركة بيلد يارد في القاهرة، وهي متخصصة في التصميم الداخلي والخارجي الشامل للعقارات السكنية والتجارية. تشتهر بيلد يارد بمعاييرها العالية في إدارة البناء، وهي ملتزمة بتوفير مساحات جميلة من الناحية الجمالية وسليمة من الناحية البنيوية. الجوانب الرئيسية',
            points: [
                'تأسست في عام 2014، مع التركيز على الحرفية والاهتمام بالتفاصيل',
                'توفير خدمات إدارة البناء الكاملة وتقدير التكلفة وتخطيط المشروع، مما يضمن تجربة بناء سلسة',
                'تشتهر بأعمالها في المجمعات البارزة مثل أليجريا، وسوديك، ونيو جيزة',
                'تقدم حلولاً مخصصة من الهندسة الإنشائية إلى حمامات السباحة والبلاط والسباكة والأعمال الكهربائية',
                'شريك موثوق به لإنشاء مساحات تعزز جودة الحياة، وتحقق رؤى العملاء من خلال الخبرة والاحترافية'
            ]
        }
    }
}