export const Mission = {
    missionEn : {
        1: 'To reach our long-term vision by working day by day towards achieving our vision. Therefore, we will undertake the following:',
        2: 'We will offer first-time property sales and assist property owners in selling their assets. Additionally, we will provide all types of real estate properties like: residential, administrative, commercial, medical, coastal, and more, alongside finishing services with the help of Buildyard design and construction company.'
    },
    missionAr : {
        1: ':تحقيق رؤيتنا طويلة المدى من خلال العمل يوما بعد يوم لتحقيق رؤيتنا، ولذلك فإننا سوف نقوم بما يلي',
        2: 'سنقدم خدمات بيع العقارات لأول مرة ومساعدة أصحاب العقارات في بيع أصولهم. بالإضافة إلى ذلك، سنوفر جميع أنواع العقارات مثل: السكنية والإدارية والتجارية والطبية والساحلية والمزيد، إلى جانب خدمات التشطيب بمساعدة شركة.'+' Buildyard '+'للتصميم والبناء'
    }
} 

export const Vision = {
    visionEn : {
        1: 'Simply, Nubaq exists to make the real estate market better.',
        2: 'Better for clients, developers &brokers by not just selling properties like many brokerage companies do, but also because we provide a real estate clubhouse (comprehensive service) addressing all aspects of your future property from A to Z, including after-sales service.',
        3: `Our Goal: We aim to establish a new concept in real estate sales, prioritizing credibility before making the sale, ensuring that it aligns with the client's needs rather than merely achieving the company's desired sales at the client’s expense.`,
        4: 'We seek to create a school for teaching the art of real estate sales from the ground up, focusing particularly on properties from the largest real estate developers in Egypt, such as: Emaar Misr, Palm Hills, SODIC, Ora, Orascom, Hassan Allam, Mountain View, and others. We also aim to include developers in Dubai like: Emaar Dubai, Damac Properties, Shoba Properties, and others.',
        5: 'By the year 2025, we aim to help 1,000 clients in Egypt.',
        6: 'By the year 2030, we aim to help 100,000 clients around the world.'
    },
    visionAr : {
        1: 'إلى تحسين سوق العقارات'+' Nubaq '+'ببساطه, تهدف شركة',
        2: 'أفضل للعملاء والمطورين والوسطاء ليس فقط من خلال بيع العقارات كما تفعل العديد من شركات الوساطة، ولكن أيضًا لأننا نقدم ناديًا عقاريًا (خدمة شاملة) يتناول جميع جوانب ممتلكاتك المستقبلية من الألف إلى الياء، بما في ذلك خدمة ما بعد البيع.',
        3: 'هدفنا: نهدف إلى إرساء مفهوم جديد في مبيعات العقارات، وإعطاء الأولوية للمصداقية قبل إجراء البيع، والتأكد من أنه يتماشى مع احتياجات العميل بدلاً من مجرد تحقيق المبيعات المرجوة للشركة على حساب العميل.',
        4: 'نسعى لإنشاء مدرسة لتعليم فن بيع العقارات من الألف إلى الياء، مع التركيز بشكل خاص على عقارات أكبر مطوري العقارات في مصر، مثل: إعمار مصر، بالم هيلز، سوديك، أورا، أوراسكوم، حسن علام، ماونتن فيو، وغيرها. كما نهدف إلى ضم مطورين في دبي مثل: إعمار دبي، داماك العقارية، شوبا العقارية، وغيرها.',
        5: 'بحلول عام 2025، نهدف إلى مساعدة 1000 عميل في مصر.',
        6:  'بحلول عام 2030، نهدف إلى مساعدة 100 ألف عميل حول العالم.'

    }
}

export const Values = {

}